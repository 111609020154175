<template>
  <div class="map-ol" :id="'map-'+ title"></div>
</template>

<script>

export default {
  name: "MapsVue",
  props:{
    title : String,
    color : String,
    backgroundColor : String,
    gridCS : String,
    gridCE : String,
    gridRS : String,
    gridRE : String
  },
}
</script>

<style scoped>
.map-ol{
  grid-column-start: v-bind(gridCS);
  grid-column-end: v-bind(gridCE);
  grid-row-start: v-bind(gridRS);
  grid-row-end: v-bind(gridRE);
}
</style>