/* eslint-disable */

import * as volt from '@/volt';
import * as list from '@/services/listes';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Chart from 'chart.js/auto';
import Gauge from 'chartjs-gauge';
import {log} from "ol/console";
const back_color =  [
    '#F4AF0A99', '#7C686499', '#A6432A99', '#A89E9C99', '#CD791A99', '#A89E9C99', '#BA5E2299', '#92838099', '#A6432A99', '#7C686499',
    '#F4AF0A99', '#D3D3D399', '#E1941299', '#BDB8B799', '#CD791A99', '#A89E9C99', '#BA5E2299', '#92838099', '#A6432A99', '#7C686499',
    '#F4AF0A99', '#D3D3D399', '#E1941299', '#BDB8B799', '#CD791A99', '#A89E9C99', '#BA5E2299', '#92838099', '#A6432A99', '#7C686499',
    '#F4AF0A99', '#D3D3D399', '#E1941299', '#BDB8B799', '#CD791A99', '#A89E9C99', '#BA5E2299', '#92838099', '#A6432A99', '#7C686499'
]
const border_color = [
    '#F4AF0A', '#7C6864', '#A6432A', '#A89E9C',
    '#CD791A', '#A89E9C', '#BA5E22', '#928380',
    '#A6432A', '#7C6864', '#F4AF0A', '#D3D3D3',
    '#E19412', '#BDB8B7', '#CD791A', '#A89E9C',
    '#BA5E22', '#928380', '#A6432A', '#7C6864',
    '#F4AF0A', '#D3D3D3', '#E19412', '#BDB8B7',
    '#CD791A', '#A89E9C', '#BA5E22', '#928380',
    '#A6432A', '#7C6864', '#F4AF0A', '#D3D3D3',
    '#E19412', '#BDB8B7', '#CD791A', '#A89E9C',
    '#BA5E22', '#928380', '#A6432A', '#7C6864'
]
// const labels =[null,2020,2021,2022,null] ;
// const data = {
//     labels: labels,
//     datasets: [{
//         label: 'My First Dataset',
//         data: [65, 59, 80, 81],
//         fill: false,
//         borderColor: 'rgb(75, 192, 192)',
//         tension: 0.1
//     }]
// };

const getOrCreateLegendList = (chart, id) => {
    const legendContainer = document.getElementById(id);
    let listContainer = legendContainer.querySelector('ul');

    if (!listContainer) {
        listContainer = document.createElement('ul');
        listContainer.style.display = 'flex';
        listContainer.style.flexDirection = 'row';
        listContainer.style.flexWrap = 'wrap';

        listContainer.style.margin = 0;
        listContainer.style.padding = 0;

        legendContainer.appendChild(listContainer);
    }

    return listContainer;
};
const htmlLegendPlugin = {
    id: 'htmlLegend',
    afterUpdate(chart, args, options) {
        const ul = getOrCreateLegendList(chart, options.containerID);

        // Remove old legend items
        while (ul.firstChild) {
            ul.firstChild.remove();
        }

        // Reuse the built-in legendItems generator
        const items = chart.options.plugins.legend.labels.generateLabels(chart);


        items.forEach((item,i) => {
            const li = document.createElement('li');
            li.style.alignItems = 'center';
            li.style.cursor = 'pointer';
            li.style.display = 'flex';
            li.style.flexDirection = 'row';
            li.style.justifyContent = 'start';
            li.style.marginLeft = '20px';
            li.style.marginBottom = '5px';

            li.onclick = () => {
                const {type} = chart.config;
                if (type === 'pie' || type === 'doughnut') {
                    // Pie and doughnut charts only have a single dataset and visibility is per item
                    chart.toggleDataVisibility(item.index);
                } else {
                    chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
                }
                chart.update();
            };

            // Color box
            const boxSpan = document.createElement('span');
            boxSpan.style.background = item.fillStyle;
            boxSpan.style.borderColor = item.strokeStyle;
            boxSpan.style.borderWidth = item.lineWidth + 'px';
            boxSpan.style.display = 'inline-block';
            // boxSpan.style.flexShrink = 0;
            boxSpan.style.height = '20px';
            boxSpan.style.marginRight = '10px';
            boxSpan.style.width = '50px';

            // Text
            const textContainer = document.createElement('p');
            textContainer.style.color = item.fontColor;
            textContainer.style.margin = 0;
            textContainer.style.padding = 0;
            textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

            const text = document.createTextNode(item.text);
            textContainer.appendChild(text);

            li.appendChild(boxSpan);
            li.appendChild(textContainer);
            ul.appendChild(li);
        });
    }
};
export function histogram(title, datas, legend, legend_html, links, total = 0){
    let labels = [];
    let _datas = [];
    let _datasPerc = [];
    let _total = 0;
    datas.forEach((data)=>{
        Object.keys(data).forEach((label)=>{
            labels.push(label);
        })
    })
    labels.forEach((label, i)=>{
        if(datas[i]){
            _datas.push(datas[i][label]);
            _total += parseFloat(datas[i][label]);
        }
    })
    _datas.forEach((val)=>{
        if(total === 0){
            _datasPerc.push(Math.round((val * 100 / _total) * 10) / 10);
        }else{
            _datasPerc.push(Math.round((val * 100 / total) * 10) / 10);
        }
    })
    const ctx = volt.tak('histogram-'+title)
    const chart = new Chart(ctx, {
        plugins: [ChartDataLabels],
        type: 'bar',
        data: {
            labels: labels,
            datasets: [
                {
                    _data: _datas,
                    data: _datasPerc,
                    backgroundColor: back_color[0],
                    datalabels: {
                        backgroundColor: border_color[0]+'33',
                        borderRadius : 5,
                        font : {size : '12px', weight: 'bold'},
                        anchor : 'end',
                        align : 'top',
                        clamp : true,
                        cursor : 'pointer',
                        textAlign : 'center',
                        // offset: 1,
                        listeners: {
                            enter: function(context, event) {
                                context.hovered = true;
                                return true;
                            },
                            leave: function(context, event) {
                                context.hovered = false;
                                return true;
                            },
                            click: function(context, event) {
                                volt.list =  {
                                    name : links[context.dataIndex],
                                    value : '',
                                    size : 0,
                                    type : '',
                                    limit : 0,
                                    offset : 0,
                                    order : 1,
                                    by : ''
                                };
                            }
                        },
                        color: function(context) {
                            return context.hovered ? border_color[0] : "gray";
                        },
                        borderColor:function(context) {
                            return context.hovered ? border_color[0] : "";
                        },
                        borderWidth:function(context) {
                            return context.hovered ? "2" : "0";
                        },
                    }
                }
            ]
        },
        options: {
            maintainAspectRatio: false,
            scales: {
                y: {
                    stacked: true,
                    beginAtZero: true,
                    grid: {
                        display : true
                    },
                    min: 0,
                    max: 100
                },
                x : {
                    stacked: true,
                    grid: {
                        display : false
                    }
                }
            },
            plugins: {
                legend: {
                    display: legend
                },
                datalabels: {
                    formatter: function(value, context) {
                        return [ value.toString().replace('.',',') +' %'];
                    }
                },
                tooltip: {
                    callbacks: {
                        label: function(context) {
                            console.log(context)
                            if(context.dataset._data[context.dataIndex][context.label]){
                                return context.label + ': ' + context.dataset._data[context.dataIndex][context.label] + ' annonces';
                            }else{
                                return context.label + ': ' + context.dataset._data[context.dataIndex] + ' clicks';
                            }

                        }
                    }
                }


            },
            onClick: (e, el, chart) => {
                if(el[0]){
                    volt.list =  {
                        name : links[el[0].index],
                        value : '',
                        size : 0,
                        type : '',
                        limit : 0,
                        offset : 0,
                        order : 1,
                        by : ''
                    };
                }
            }
        }
    });
    return chart
}
export function histogram_horizontal(title, datas, legend, legend_html, links, colors, total = 0){
    let backColor = back_color[0];
    if(colors === 'multi'){
        backColor = back_color;
    }
    let labels = [];
    let _datas = [];
    let _datasPerc = [];
    let _total = 0;
    datas.forEach((data)=>{
        Object.keys(data).forEach((label)=>{
            labels.push(label);
        })
    })
    labels.forEach((label, i)=>{
        if(datas[i]){
            _datas.push(datas[i][label]);
            _total += parseFloat(datas[i][label]);
        }
    })
    _datas.forEach((val)=>{
        if(total === 0){
            _datasPerc.push(Math.round((val * 100 / _total) * 10) / 10);
        }else{
            _datasPerc.push(Math.round((val * 100 / total) * 10) / 10);
        }
    })
    const ctx = volt.tak('histogram-'+title)
    const chart = new Chart(ctx, {
        plugins: [ChartDataLabels],
        type: 'bar',
        data: {
            labels: labels,
            datasets: [
                {
                    _data: datas,
                    data: _datasPerc,
                    backgroundColor: backColor,
                    datalabels: {
                        backgroundColor: border_color[0]+'33',
                        borderRadius : 5,
                        font : {size : '12px', weight: 'bold'},
                        anchor : 'end',
                        align : 'end',
                        clamp : true,
                        cursor : 'pointer',
                        textAlign : 'center',
                        offset: 5,
                        listeners: {
                            enter: function(context, event) {
                                context.hovered = true;
                                return true;
                            },
                            leave: function(context, event) {
                                context.hovered = false;
                                return true;
                            },
                            click: function(context, event) {
                                volt.list =  {
                                    name : links[context.dataIndex],
                                    value : '',
                                    size : 0,
                                    type : '',
                                    limit : 0,
                                    offset : 0,
                                    order : 1,
                                    by : ''
                                };
                            }
                        },
                        color: function(context) {
                            return context.hovered ? border_color[0] : "gray";
                        },
                        borderColor:function(context) {
                            return context.hovered ? border_color[0] : "";
                        },
                        borderWidth:function(context) {
                            return context.hovered ? "2" : "0";
                        },
                    }
                }
            ]
        },
        options: {
            indexAxis: 'y',
            maintainAspectRatio: false,
            scales: {
                y: {
                    stacked: true,
                    beginAtZero: true,
                    grid: {
                        display : false
                    },
                    min: 0,
                    max: 100
                },
                x : {
                    stacked: true,
                    display: false,
                    grid: {
                        display : false
                    },
                    min: 0,
                    max: 130
                }
            },
            plugins: {
                legend: {
                    display: legend,
                },
                datalabels: {
                    formatter: function(value, context) {
                        let result = [value.toString().replace('.',',') +' %'];
                        return result;
                    }
                },
                tooltip: {
                    callbacks: {
                        label: function(context) {
                            let def = {
                                "Occasionnel": "Location jusqu'à 15 jours par an.",
                                "Régulier": "Location entre 16 et 60 jours par an.",
                                "Très fréquent": "Location entre 61 et 120 jours par an.",
                                "Investisseur": "Location supérieure à 120 jours par an."
                            };
                            if(context.chart.canvas.id.substring(0,19) === "histogram-connexion"){
                                return [context.label + ': ' + context.dataset._data[context.dataIndex][context.label] + ' clicks', def[context.label] ? def[context.label] : ''];
                            }else {
                                return [context.label + ': ' + context.dataset._data[context.dataIndex][context.label] + ' annonces', def[context.label] ? def[context.label] : ''];
                            }
                        }
                    }
                }

            },
            onClick: (e, el, chart) => {
                if(el[0]){
                    volt.list =  {
                        name : links[el[0].index],
                        value : '',
                        size : 0,
                        type : '',
                        limit : 0,
                        offset : 0,
                        order : 1,
                        by : ''
                    };
                }
            }
        }
    });
    return chart
}
export function multi_bar_horizontal(title, datas){
    let labels = ['', ''];
    let datasets = [];
    let dataPerc = [[],[]];
    let total0 = 0;
    let total1 = 0;
    datas[0].forEach((data)=> {
        if(data.value !== ''){
            total0 += parseFloat(data.value);
        }
    });
    datas[1].forEach((data)=> {
        if(data.value !== '') {
            total1 += parseFloat(data.value);
        }
    });
    datas[0].forEach((data)=> {
        if(isNaN(data.value * 100 / total0)){
            dataPerc[0].push(0);
        }else{
            dataPerc[0].push(Math.round((data.value * 100 / total0) * 10) / 10);
        }
    });
    datas[1].forEach((data)=> {
        if(isNaN(data.value * 100 / total1)){
            dataPerc[1].push(0);
        }else{
            dataPerc[1].push(Math.round((data.value * 100 / total1) * 10) / 10);
        }
    });
    datas[0].forEach((data, c)=>{
        let dataset = {
            label: [c],
            data: [dataPerc[0][c], dataPerc[1][c]],
            fill: false,
            backgroundColor: back_color[c],
            datalabels: {
                backgroundColor: 'transparent',
                borderRadius: 5,
                font: {size: '10px', weight: 'bold'},
                anchor: 'center',
                align: 'center',
                cursor: 'pointer',
                textAlign: 'center',
                display: 'auto'
                // offset: 1,
            }
        }
        datasets.push(dataset);
    });
    const ctx = volt.tak('bar-'+title)
    const chart = new Chart(ctx, {
        type: 'bar',
        data: {
            labels: labels,
            datasets: datasets
        },
        options: {
            indexAxis: 'y',
            maintainAspectRatio: false,
            scales: {
                y: {
                    stacked: true,
                    beginAtZero: true,
                    grid: {
                        display : true
                    },
                    display: false,
                },
                x : {
                    min: 0,
                    max: 100,
                    display: false,
                    stacked: true,
                    grid: {
                        display : false
                    }
                }
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function(context) {
                            // let label = context.dataset.label || '';
                            let label = datas[context.dataIndex][context.datasetIndex].label;
                            if (label) {
                                label += ': '+context.formattedValue + ' %';
                            }
                            return label;
                        }
                    }
                },
                htmlLegend: {
                    // ID of the container to put the legend in
                    containerID: 'legend-'+title,
                },
                legend: {
                    display: false
                },
                datalabels: {
                    formatter: function(value, context) {
                        let label = datas[context.dataIndex][context.datasetIndex].label;
                        if(value > 0) return [ label, value.toString().replace('.',',') +' %'];
                        else return '';
                    }
                }
            }
        },
        plugins: [ChartDataLabels],

    });
    return chart
}
export function multi_bar(title, datas, legend, legend_html, links, colors){
    let backColor = back_color[0];
    if(colors === 'multi'){
        backColor = back_color;
    }
    let labels = [];
    let legends = [];
    let _datas = [];
    let _datasPerc = [];
    let dataTooltip = [];
    let total = [];
    let total_other = [];
    let indexs = [];
    datas.forEach((data, c)=>{
        Object.keys(data).forEach((label)=>{
            if(label === 'undefined' || label === 'Non concernée' || label === '' || label === 'Autre' || label.replace(' ', '') === 'Autres'){
                Object.keys(data[label]).forEach((legend)=>{
                    if(data[label][legend]){
                        if(total_other[legend] === undefined){
                            total_other[legend] = parseFloat(data[label][legend]);
                        }else{
                            total_other[legend] += parseFloat(data[label][legend]);
                        }
                    }
                });
                indexs.push(c);
            }
        })
    })
    for(let i = indexs.length -1; i >= 0; i--){
        datas.splice(indexs[i], 1);
    }
    datas.unshift({'Autres' : total_other});
    datas.forEach((data, c)=>{
        Object.keys(data).forEach((label)=>{
            Object.keys(data[label]).forEach((legend)=>{
                if(!legends.includes(legend)){
                    legends.push(legend);
                    total[legend] = 0;
                    _datasPerc[legend] = [];
                    dataTooltip[legend] = [];
                }
            })
            labels.push(label);
        })
    })
    datas.forEach((data, c)=>{
       legends.forEach((legend)=>{
           if(data[labels[c]][legend] === undefined){
               data[labels[c]][legend] = 0;
           }
           total[legend] += parseFloat(data[labels[c]][legend]);
       })
    });
    datas.forEach((data, c)=>{
        legends.forEach((legend)=>{
            _datasPerc[legend].push(Math.round((data[labels[c]][legend] * 100 / total[legend]) * 10) / 10);
            dataTooltip[legend].push(data[labels[c]][legend]);
        });
    });
    const ctx = volt.tak('histogram-'+title)
    legends.forEach((legend, i)=>{
            let dataset = {
                label: legend,
                data:  _datasPerc[legend],
                _data:  dataTooltip[legend],
                backgroundColor: backColor[i],
                datalabels: {
                    backgroundColor: border_color[0]+'33',
                    borderRadius : 5,
                    font : {size : '12px', weight: 'bold'},
                    anchor : 'end',
                    align : 'end',
                    clamp : true,
                    cursor : 'pointer',
                    textAlign : 'center',
                    offset: 5,
                    listeners: {
                        enter: function(context, event) {
                            context.hovered = true;
                            return true;
                        },
                        leave: function(context, event) {
                            context.hovered = false;
                            return true;
                        },
                        click: function(context, event) {
                            volt.list =  {
                                name : links[context.dataIndex],
                                value : '',
                                size : 0,
                                type : '',
                                limit : 0,
                                offset : 0,
                                order : 1,
                                by : ''
                            };
                        }
                    },
                    color: function(context) {
                        return context.hovered ? border_color[0] : "gray";
                    },
                    borderColor:function(context) {
                        return context.hovered ? border_color[0] : "";
                    },
                    borderWidth:function(context) {
                        return context.hovered ? "2" : "0";
                    },
                }
            }
        _datas.push(dataset);
    });
    const chart = new Chart(ctx, {
        plugins: [htmlLegendPlugin, ChartDataLabels],
        type: 'bar',
        data: {
            labels: labels,
            datasets: _datas
        },
        options: {
            indexAxis: 'x',
            maintainAspectRatio: false,
            scales: {
                y: {
                    // stacked: true,
                    beginAtZero: true,
                    grid: {
                        display : true
                    },
                    min: 0,
                    max: 140,
                    display: false,
                },
                x : {
                    // stacked: true,
                    grid: {
                        display : false
                    },
                    min: 0,
                    max: 110
                }
            },
            plugins: {
                htmlLegend: {
                    // ID of the container to put the legend in
                    containerID: 'legend-'+title,
                },
                legend: {
                    display: legend
                },
                datalabels: {
                    formatter: function(value, context) {
                        return [ value.toString().replace('.',',') +' %'];
                    }
                },
                tooltip: {
                    callbacks: {
                        label: function(context) {
                            console.log(context)
                            return context.dataset.label + ': ' + context.dataset._data[context.dataIndex] + ' annonces';
                        }
                    }
                }

            },
            onClick: (e, el, chart) => {
                if(el[0]){
                    volt.list =  {
                        name : links[el[0].index],
                        value : '',
                        size : 0,
                        type : '',
                        limit : 0,
                        offset : 0,
                        order : 1,
                        by : ''
                    };
                }
            }
        }
    });
    return chart
}
export function multi_histogram(title, datas, legend){
    let labels = [];
    let lab_tab = [];
    let datasets = [];
    datas.forEach((data)=>{
        Object.keys(data).forEach((label)=>{
            labels.push(label);
            Object.keys(data[label]).forEach((l)=> {
                if (!lab_tab.includes(l)) {
                    lab_tab.push(l);
                }
            });
        })
    })
    let total = [];
    labels.forEach((label, i)=>{
        total[i] = 0;
    });
    lab_tab.forEach((type, c)=>{
        labels.forEach((label, i)=>{
            if(datas[i][label][type])total[i] += datas[i][label][type];
        });
    })
    lab_tab.forEach((type, c)=>{
        let _datasPerc = [];
        labels.forEach((label, i)=>{
            if(isNaN(datas[i][label][type] * 100 / total[i])){
                _datasPerc.push(0);
            }else{
                _datasPerc.push(Math.round((datas[i][label][type] * 100 / total[i]) * 10) / 10);
            }
        });
        let lab = type;
        type === '' ? lab = 'Non renseigné' : lab = type;
        let dataset = {
            label: lab,
            data: _datasPerc,
            fill: false,
            backgroundColor: back_color[c],
            datalabels: {
                backgroundColor: 'transparent',
                borderRadius: 5,
                font: {size: '12px', weight: 'bold'},
                anchor: 'end',
                align: 'bottom',
                cursor: 'pointer',
                textAlign: 'center',
                display : 'auto'
                // offset: 1,
            }
        }
        datasets.push(dataset);
    });

    const ctx = volt.tak('histogram-'+title)
    const chart = new Chart(ctx, {
        type: 'bar',
        data: {
            labels: labels,
            datasets: datasets
        },
        options: {
            maintainAspectRatio: false,
            scales: {
                y: {
                    stacked: true,
                    beginAtZero: true,
                    grid: {
                        display : true
                    }
                },
                x : {
                    stacked: true,
                    grid: {
                        display : false
                    }
                }
            },
            plugins: {
                htmlLegend: {
                    // ID of the container to put the legend in
                    containerID: 'legend-'+title,
                },
                legend: {
                    display: legend
                },
                datalabels: {
                    formatter: function(value, context) {
                        if(value > 0) return [ value.toString().replace('.',',') +' %'];
                        else return '';
                    }
                }
            }
        },
        plugins: [htmlLegendPlugin, ChartDataLabels],

    });
    return chart
}
export function gauge(title, value, kpis){
    const ctx = volt.tak('gauge-'+title)
    const chart = new Gauge(ctx, {
        type: 'gauge',
        data: {
            datasets: [{
                data: [33,70,100],
                value: value,
                backgroundColor: [ "rgb(238, 182, 144)" , "rgb(241, 222, 158)" , "rgb(185, 228, 193)" ],
                borderWidth: 1
            }]
        },
        options: {
            maintainAspectRatio: false,
            responsive: true,
            title: {
                display: false,
            },
            layout: {
                padding: {
                    bottom: 30
                }
            },
            needle: {
                // Needle circle radius as the percentage of the chart area width
                radiusPercentage: 2,
                // Needle width as the percentage of the chart area width
                widthPercentage: 3.2,
                // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
                lengthPercentage: 80,
                // The color of the needle
                color: 'rgb(63,62,62)'
            },
            valueLabel: {
                display: true,
                formatter: (value) => {
                    return value+ " %";
                },
                color: '#000000',
                backgroundColor: '#F4AF0Add',
                borderRadius: 5,
                padding: {
                    top: 10,
                    bottom: 10
                },
                fontSize: 15
            }
        }
    });
    return chart
}

export function line(title, datas, legend){
    let labels = [];
    let _datas = [];
    datas.forEach((data)=>{
        if(data.annee){
            labels.push(data.annee);
        }else{
            labels.push(data.label);
        }
        _datas.push(data.value);
    })
    const ctx = volt.tak('line-'+title)
    const chart = new Chart(ctx, {
        type: 'line',
        data: {
            labels: labels,
            datasets: [{
                data: _datas,
                fill: false,
                borderColor: border_color[0],
                tension: 0.1
            }]
        },
        options: {
            maintainAspectRatio: false,
            scales: {
                y: {
                    beginAtZero: false
                },
                x : {
                    beginAtZero: true
                }

            },
            plugins: {
                legend: {
                    display: legend
                },
                tooltip: {
                    callbacks: {
                        label: function(context) {
                            console.log(context.chart.canvas.id)
                            if(context.chart.canvas.id === "line-Evolution du nb. de jours loués"){
                                return context.formattedValue + ' jour(s) loué(s)';
                            }else{
                                return context.formattedValue + ' annonce(s)';
                            }
                        }
                    }
                }
            }
        }
    });
    return chart
}

export function multi_line(title, datas, legend){
    let labels = [];
    let lab_tab = [];
    let datasets = [];
    datas.forEach((data)=>{
        Object.keys(data).forEach((label)=>{
            labels.push(label);
            Object.keys(data[label]).forEach((l)=> {
                if (!lab_tab.includes(l)) {
                    lab_tab.push(l);
                }
            });
        })
    })
    lab_tab.sort();
    lab_tab.forEach((type, c)=>{
        let _datas = [];
        labels.forEach((label, i)=>{
            _datas.push(datas[i][label][type]);
        });
        let dataset = {
            label: type.charAt(0).toUpperCase()+ type.slice(1),
            data: _datas,
            fill: false,
            borderColor: border_color[c],
            tension: 0.1
        }
        datasets.push(dataset);
    })
    const ctx = volt.tak('line-'+title)
    const chart = new Chart(ctx, {
        type: 'line',
        data: {
            labels: labels,
            datasets: datasets
        },
        options: {
            maintainAspectRatio: false,
            scales: {
                y: {
                    beginAtZero: false
                },
                x : {
                    beginAtZero: true
                }
            },
            plugins: {
                legend: {
                    display: legend
                },
                tooltip: {
                    callbacks: {
                        label: function(context) {
                            return context.dataset.label + ': ' + context.formattedValue + ' annonces';
                        }
                    }
                }
            }
        }
    });
    return chart
}






/**
 *
 * @param canva
 * @param data
 * @param ordonnees
 * @param title
 * @param calc
 * @param add
 * @param colors
 * @param colorsUser
 * @param aliasUser
 * @returns {*}
 */
export function _histogram(canva, data, ordonnees, title, calc, add, colors, colorsUser, aliasUser){
    if(data[0]['data'] !== undefined && data[0]['data'][0].totalGeneral !== undefined && data[0].label !== undefined){
        return histogram_multi_multiOrd(canva, data, ordonnees, title, "bar", calc, colors, colorsUser, aliasUser)
    }else if(data[0].totalGeneral !== undefined){
        return histogram_multi_bar(canva, data, ordonnees, title, calc, colors, colorsUser, aliasUser);
    }else if(data[0].label !== undefined){
        return histogram_simple_multiOrd(canva, data, ordonnees, title, calc, colors, colorsUser, aliasUser);
    }else{
        return histogram_simple(canva, data, ordonnees, title, calc, add, colors, aliasUser);
    }
}

/**
 *
 * @param canva
 * @param data
 * @param ordonnees
 * @param title
 * @param calc
 * @param add
 * @param colors
 * @param colorsUser
 * @param aliasUser
 * @returns {*}
 * @private
 */
export function _doughnut(canva, data, ordonnees, title, calc, add, colors, colorsUser, aliasUser){
    if(data[0]['data'] !== undefined && data[0]['data'][0].totalGeneral !== undefined && data[0].label !== undefined){
        return histogram_multi_multiOrd(canva, data, ordonnees, title, "bar", calc, colors, colorsUser, aliasUser)
    }else if(data[0].totalGeneral !== undefined){
        return multiPie(canva, data, ordonnees, title, calc, colors, aliasUser);
    }else if(data[0].label !== undefined){
        return histogram_simple_multiOrd(canva, data, ordonnees, '', calc, colors, colorsUser, aliasUser);
    }else{
        return doughnut(canva, data, ordonnees, title, calc, colors, aliasUser);
    }
}

/**
 *
 * @param canva
 * @param data
 * @param ordonnees
 * @param title
 * @param calc
 * @param add
 * @param colors
 * @param colorsUser
 * @param aliasUser
 * @returns {*}
 * @private
 */
export function _pie(canva, data, ordonnees, title, calc, add, colors, colorsUser, aliasUser){
    if(data[0]['data'] !== undefined && data[0]['data'][0].totalGeneral !== undefined && data[0].label !== undefined){
        return histogram_multi_multiOrd(canva, data, ordonnees, title, "bar", calc, colors, colorsUser, aliasUser)
    }else if(data[0].totalGeneral !== undefined){
        return multiPie(canva, data, ordonnees, title, calc, colors, aliasUser);
    }else if(data[0].label !== undefined){
        return histogram_simple_multiOrd(canva, data, ordonnees, title, calc, colors, colorsUser, aliasUser);
    }else{
        return pie(canva, data, ordonnees, title, colors, aliasUser);
    }
}

/**
 *
 * @param canva
 * @param data
 * @param ordonnees
 * @param title
 * @param calc
 * @param add
 * @param colors
 * @param colorsUser
 * @param aliasUser
 * @returns {*}
 */
export function _mixte(canva, data, ordonnees, title, calc, add, colors, colorsUser, aliasUser){
    if(data[0]['data'] !== undefined && data[0]['data'][0].totalGeneral !== undefined && data[0].label !== undefined){
        return histogram_multi_multiOrd(canva, data, ordonnees, title, "line", calc, colors, colorsUser, aliasUser)
    }else if(data[0].totalGeneral !== undefined){
        return histogram_multi_bar(canva, data, ordonnees, title, calc, colors, colorsUser, aliasUser);
    }else if(data[0].label !== undefined){
        return histogram_simple_multiOrd(canva, data, ordonnees, title, calc, colors, colorsUser, aliasUser);
    }else{
        return histogram_simple(canva, data, ordonnees, title, calc, add, colors, aliasUser);
    }
}

/**
 *
 * @param canva
 * @param data
 * @param ordonnees
 * @param title
 * @param calc
 * @param add
 * @param colors
 * @param colorsUser
 * @param aliasUser
 * @returns {*}
 */
export function _courbe(canva, data, ordonnees, title, calc, add, colors, colorsUser, aliasUser){
    if(data[0]['data'] !== undefined && data[0]['data'][0].totalGeneral !== undefined && data[0].label !== undefined){
        return histogram_multi_multiOrd(canva, data, ordonnees, title, "fullLine", calc, colors, colorsUser, aliasUser)
    }else if(data[0].totalGeneral !== undefined){
        return histogram_multi_bar(canva, data, ordonnees, title, calc, colors, colorsUser, aliasUser);
    }else if(data[0].label !== undefined){
        return histogram_simple_multiOrd(canva, data, ordonnees, title, calc, colors, colorsUser, aliasUser);
    }else{
        return courbe_simple(canva, data, ordonnees, title,false, calc, colors, aliasUser);
    }
}
/**
 *
 * @param canva
 * @param data
 * @param ordonnees
 * @param title
 * @param calc
 * @param colors
 * @param colorsUser
 * @param aliasUser
 * @returns {*}
 */
export function histogram_multi_bar(canva, data, ordonnees, title, calc, colors, colorsUser, aliasUser){
    var dataClean = volt.dataFilter(data);
    var datasets = [];
    var textExt = '';
    var labelTab = [];
    if(calc === 'percent')textExt = ' %';
    dataClean.labels.forEach((label)=>{
        labelTab.push(aliasUser.alias_labels[label]);
    });
    dataClean.legends.forEach((item, i)=>{
        let colorBorder = colors.border_color[i];
        let colorBack = colors.back_color[i];
        if(colors.border_color[aliasUser.alias_legends[item]])colorBorder = colors.border_color[aliasUser.alias_legends[item]];
        if(colors.back_color[aliasUser.alias_legends[item]])colorBack = colors.back_color[aliasUser.alias_legends[item]];

        datasets.push(
            {
                // label: item,
                label: aliasUser.alias_legends[item],
                data: dataClean.values[i],
                borderColor: colorBorder,
                backgroundColor:colorBack,
                borderWidth: 1
            }
        );
    });
    var data_js = {
        labels:  labelTab,
        datasets: datasets
    };
    const ctx = document.getElementById(canva);
    const myChart = new Chart(ctx, {
        type: 'bar',
        data: data_js,
        options: {
            maintainAspectRatio: false,
            scales: {
                y: {
                    beginAtZero: true,
                    title: {
                        display: true,
                        text: ordonnees[0]+textExt
                    }
                },
            },
            plugins: {
                legend: {
                    position: 'top',
                },
                title: {
                    display: true,
                    text: title
                }
            }
        }
    });
    return myChart;
}

/**
 *
 * @param canva
 * @param data
 * @param ordonnees
 * @param title
 * @param calc
 * @param addCalc
 * @param colors
 * @param aliasUser
 * @returns {*}
 */
export function histogram_simple(canva, data, ordonnees, title, calc, addCalc, colors, aliasUser){
    var labels = [];
    var labelsAdd = [];
    var datas = [];
    var textExt = '';
    var total = 0;
    if(calc === 'percent')textExt = ' %';
    data.forEach((el)=>{
        if(el.Value.$numberDecimal !== undefined){
            datas.push(el.Value.$numberDecimal);
            total += Number.parseFloat(el.Value.$numberDecimal);
        }else{
            datas.push(el.Value);
            total += Number.parseFloat(el.Value);
        }
        labels.push(aliasUser.alias_labels[el._id]);
    });
    var data_js = {
        labels: labels,
        datasets: [{
            data:datas,
            backgroundColor: (p)=>{
                return filterColors(p, colors, total, labels).back_color;
            },
            borderColor: (p)=>{
                return filterColors(p, colors, total, labels).border_color;
            },
            borderWidth: 1,
            stack: 'combined'
        }]
    }
    if(addCalc !== undefined && addCalc !== ''){
        addCalc.forEach((add, i)=>{
            var datasAdd = [];
            data.forEach((el)=>{
                if(add.Value.$numberDecimal !== undefined){
                    datasAdd.push(add.Value.$numberDecimal);
                }else{
                    datasAdd.push(add.Value);
                }
            });
            data_js.datasets.push({
                type : "line",
                label : add._id,
                data :  datasAdd,
                borderWidth: 1,
                backgroundColor: (p)=>{
                    var back_color = filterColors(p, colors, total).back_color;
                    if(back_color[i] !== undefined){
                        return back_color[i]
                    }else{
                        return back_color[0]
                    }
                },
                borderColor: (p)=>{
                    var border_color = filterColors(p, colors, total).border_color;
                    if(border_color[i] !== undefined){
                        return border_color[i]
                    }else{
                        return border_color[0]
                    }
                },
                stack: 'combined'
            });
        });

    }
    const ctx = document.getElementById(canva);
    const myChart = new Chart(ctx, {
        type: 'bar',
        data: data_js,
        options: {
            maintainAspectRatio: false,
            scales: {
                y: {
                    beginAtZero: true,
                    title : {
                        display : true,
                        text : ordonnees[0]+textExt
                    }
                }
            },
            plugins: {
                legend: {
                    display: false
                }
            }
        }
    });
    return myChart;
}
/**
 *
 * @param canva
 * @param data
 * @param ordonnees
 * @param title
 * @param fill
 * @param calc
 * @param colors
 * @param aliasUser
 * @returns {*}
 */
export function courbe_simple(canva, data, ordonnees, title, fill, calc, colors, aliasUser){
    var labels = [];
    var datas = [];
    var textExt = '';
    var total = 0;
    if(calc === 'percent')textExt = ' %';
    data.forEach((el)=>{
        if(el.Value.$numberDecimal !== undefined){
            datas.push(el.Value.$numberDecimal);
            total += Number.parseFloat(el.Value.$numberDecimal);
        }else{
            datas.push(el.Value);
            total += Number.parseFloat(el.Value);
        }
        // datas.push(el.Value.$numberDecimal);
        // labels.push(el._id);
        labels.push(aliasUser.alias_labels[el._id]);
    });
    var data_js = {
        labels: labels,
        datasets: [{
            // label: ordonnees,
            data:datas,
            backgroundColor: (p)=>{
                return filterColors(p, colors, total, labels).back_color;
            },
            borderColor: (p)=>{
                return filterColors(p, colors, total, labels).border_color;
            },
            borderWidth: 2,
            fill: fill,
            tension: 0.4,
        }]
    }
    const ctx = document.getElementById(canva);
    const myChart = new Chart(ctx, {
        type: 'line',
        data: data_js,
        options: {
            maintainAspectRatio: false,
            scales: {
                y: {
                    beginAtZero: true,
                    title : {
                        display : true,
                        text : ordonnees[0]+textExt
                    }
                }
            },
            plugins: {
                legend: {
                    display: false
                }
            }
        }
    });
    return myChart;
}
/**
 *
 * @param canva
 * @param data
 * @param ordonnees
 * @param title
 * @param type
 * @param calc
 * @param colors
 * @param colorsUser
 * @param aliasUser
 * @returns {*}
 */
export function histogram_multi_multiOrd(canva, data, ordonnees, title, type, calc, colors, colorsUser, aliasUser){
    var dataClean = volt.dataFilterMulti(data);
    var datasTabbyScale = [];
    var datasets = [];
    var colorCount = 0;
    var textExt = '';
    var labTab = [];
    if(calc === 'percent')textExt = ' %';
    dataClean.scales.forEach((scal, ii) => {
        datasTabbyScale[ii] = [];
    });
    dataClean.values.forEach((item, i)=>{
        dataClean.scales.forEach((scal, ii)=>{
            datasTabbyScale[ii] = Array.from(new Set( datasTabbyScale[ii].concat(item[ii])));
            var _type = "bar";
            if(type === 'fullLine'){
                _type = "line"
            }else{
                if(ii % 2 === 0)_type = type;
            }
            let sc = '';
            if(aliasUser.alias_scales[scal] === undefined){
                sc = aliasUser.alias_scales['Documents'];
            }else{
                sc = aliasUser.alias_scales[scal];
            }
            datasets.push(
                {
                    // label: dataClean.legends[i]+'-'+scal,
                    label: aliasUser.alias_legends[dataClean.legends[i]]+'~'+sc,
                    yAxisID: ii,
                    type : _type,
                    data: dataClean.values[i][ii],
                    // borderColor: colors.border_color[colorCount],
                    // backgroundColor: colors.back_color[colorCount],
                    borderWidth: 2
                }
            );
            // colorCount++;
        });
    });
    dataClean.labels.forEach((lab)=>{
        labTab.push(aliasUser.alias_labels[lab]);
    });
    var data_js = {
        // labels: dataClean.labels,
        labels: labTab,
        datasets: datasets
    };
    var config = '{"type": "bar", "data": '+JSON.stringify(data_js)+',"options": { "maintainAspectRatio": false, "scales": { ';
    var scales = [];
    dataClean.scales.forEach((l, i)=>{
        let sc = '';
        if(aliasUser.alias_scales[l] === undefined){
            sc = aliasUser.alias_scales['Documents'];
        }else{
            sc = aliasUser.alias_scales[l];
        }
        var max = '';
        var min = '';
        if(datasTabbyScale[i] !== undefined){
            var minMax = volt.min_max(datasTabbyScale[i])
            max = '"max":' + minMax.max +',';
            min = '"min":' + parseInt(parseInt(minMax.min) - (10*parseInt(minMax.min)/100)) +',';
        }
        var pos = "right";
        if(i % 2 === 0)pos = "left";
        // scales.push('"'+i + '": {"type": "linear", "position": "'+pos+'",'+ max + min +'"ticks": { "color":"'+border_color[i]+'"} }')
        if(colors.border_color !== undefined){
            scales.push('"'+i + '": {"type": "linear", "position": "'+pos+'",'+ max + min +'"ticks": { "color":"'+colors.border_color[i]+'"}, "title" : {"display" : true, "text" : "'+sc+textExt+'", "color" : "'+colors.border_color[i]+'" }}')
        }else{
            scales.push('"'+i + '": {"type": "linear", "position": "'+pos+'",'+ max + min +'"ticks": { "color":"'+colorsUser.border_color[i]+'"}, "title" : {"display" : true, "text" : "'+sc+textExt+'", "color" : "'+colorsUser.border_color[i]+'" }}')
        }
    })
    config += scales.join(',');
    config += '}}}';
    const ctx = document.getElementById(canva);
    const myChart = new Chart(ctx, JSON.parse(config));
    myChart.data.datasets.forEach((ds, i)=>{
        var minMax = volt.min_max(datasTabbyScale[ds.yAxisID])
        ds.backgroundColor = (p)=>{
            var back_color = filterColors(p, colors, minMax.max).back_color;
            if(back_color[i] !== undefined){
                return back_color[i]
            }else{
                return back_color[0]
            }
            // return filterColors(p, colors, minMax.max).back_color[0];
        };
        ds.borderColor= (p)=>{
            return colorsUser.border_color[ds.yAxisID]
            // var border_color = filterColors(p, colors, minMax.max).border_color;
            // if(border_color[i] !== undefined){
            //     return border_color[i]
            // }else{
            //     return colorsUser.border_color[ds.yAxisID]
            //     // return border_color[0]
            // }
            // return filterColors(p, colors, minMax.max).border_color[0];
        };
    })
    if(colors.border_color === undefined) {
        myChart.options.plugins = {
            legend: {
                display: false
            }
        }
    }
    myChart.update();
    return myChart;
}
/**
 *
 * @param canva
 * @param dataTab
 * @param ordonnees
 * @param title
 * @param calc
 * @param colors
 * @param colorsUser
 * @param aliasUser
 * @returns {*}
 */
export function histogram_simple_multiOrd(canva, dataTab, ordonnees, title, calc, colors, colorsUser, aliasUser){
    var labels = [];
    var datas = [];
    var lab = [];
    var legends = [];
    var textExt = '';
    if(calc === 'percent')textExt = ' %';
    var datasFiltres = [];
    var dat = [];

    dataTab.forEach((data, i)=>{
        datas[i] = [];
        let sc = '';
        if(aliasUser.alias_scales[data.label] === undefined){
            sc = aliasUser.alias_scales['Documents'];
        }else{
            sc = aliasUser.alias_scales[data.label];
        }
        legends.push(sc);
        data.data.forEach((el,iii)=>{
            dat[el._id] = []
            lab.push(aliasUser.alias_labels[el._id])
            labels.push(aliasUser.alias_labels[el._id]);
        });
    });
    dataTab.forEach((data, i)=>{
        data.data.forEach((el,iii)=>{
            if(el.Value.$numberDecimal !== undefined){
                dat[el._id].push(el.Value.$numberDecimal);
            }else{
                dat[el._id].push(el.Value);
            }
        });
    });
    dataTab.forEach((data, i)=>{
        for(var id in dat){
            datas[i].push(dat[id][i]);
        }
    });
    labels = volt.filterArray(labels);
    var datasets = [];
    // datas.forEach((data, i )=>{
    //     console.log(legends[i]);
    //     datasets.push({
    //         label: legends[i],
    //         yAxisID: lab[i],
    //         data:data,
    //         backgroundColor: colorsUser.back_color[i],
    //         borderColor: colorsUser.border_color[i],
    //         borderWidth: 1
    //     });
    // });
    datas.forEach((data, i )=>{
        datasets.push({
            label: legends[i],
            yAxisID: legends[i],
            data:data,
            backgroundColor: colorsUser.back_color[i],
            borderColor: colorsUser.border_color[i],
            borderWidth: 1
        });
    });
    var data_js = {
        labels: labels,
        datasets: datasets
    }
    var config = '{"type": "bar", "data": '+JSON.stringify(data_js)+',"options": { "maintainAspectRatio": false, "scales": { ';
    var scales = [];
    datas.forEach((l, i)=>{
        var max = '';
        var min = '';
        var minMax = volt.min_max(l)
        max = '"max":' + minMax.max +',';
        min = '"min":' + parseInt(parseInt(minMax.min) - (10*parseInt(minMax.min)/100)) +',';
        var pos = "right";
        if(i % 2 === 0)pos = "left";
        if(colors.border_color !== undefined) {
            scales.push('"' + legends[i] + '": {"type": "linear", "position": "' + pos + '",' + max + min + '"ticks": { "color":"' + colors.border_color[i] + '"}}')
        }else{
            scales.push('"' + legends[i] + '": {"type": "linear", "position": "' + pos + '",' + max + min + '"ticks": { "color":"' + colorsUser.border_color[i] + '"}}')
        }
    })
    config += scales.join(',');
    config += '}}}';
    const ctx = document.getElementById(canva);
    const myChart = new Chart(ctx, JSON.parse(config));
    myChart.data.datasets.forEach((ds, i)=>{
        let  back_color = [];
        let  border_color = [];
        var minMax = volt.min_max(datas[i]);
        ds.backgroundColor = (p)=>{
            back_color = filterColors(p, colors, minMax.max, [ds.label]).back_color;

            if(back_color[i] !== undefined){
                return back_color[i]
            }else{
                return back_color[0]
            }
        };
        ds.borderColor = (p)=>{
            border_color = filterColors(p, colors, minMax.max, [ds.label]).border_color;

            if(border_color[i] !== undefined){
                return border_color[i]
            }else{
                return border_color[0]
            }
        };
    })
    for(const scale in myChart.config.options.scales){
        if( colors.border_color[scale]){
            myChart.config.options.scales[scale].ticks.color = colors.border_color[scale];
        }
    }
}
/**
 *
 * @param canva
 * @param data
 * @param ordonnees
 * @param title
 * @param colors
 * @param aliasUser
 * @returns {*}
 */
export function histogram_line_multiOrdonnees(canva, data, ordonnees, title, colors, aliasUser){
    var labels = [];
    var datas = [];
    data.forEach((el)=>{
        if(el.Value.$numberDecimal !== undefined){
            datas.push(el.Value.$numberDecimal);
        }else{
            datas.push(el.Value);
        }
        // datas.push(el.Value.$numberDecimal);
        labels.push(el._id);
    });
    var data_js ={
        labels: ['1', '2', '3', '4', '5'],
            datasets: [
                {
                    label: 'A',
                    yAxisID: 'A',
                    data: [100, 96, 84, 76, 69],
                    fill: true,
                    tension: 0.4,
                    backgroundColor: colors.back_color[0],
                    borderColor: colors.border_color[0],
                    borderWidth: 1
                },
                {
                    label: 'B',
                    yAxisID: 'B',
                    data: [1, 0.6, 0.3, 1, 3],
                    fill: true,
                    tension: 0.4,
                    type : "bar",
                    backgroundColor: colors.back_color[1],
                    borderColor: colors.border_color[1],
                    borderWidth: 1
                },
                {
                    label: 'C',
                    yAxisID: 'C',
                    data: [1, 0.6, 0.3, 1, 0],
                    fill: true,
                    tension: 0.4,
                    type : "bar",
                    backgroundColor: colors.back_color[2],
                    borderColor: colors.border_color[2],
                    borderWidth: 1
                }
                ]
        }
    const ctx = document.getElementById(canva);
    const myChart = new Chart(ctx, {
        type: 'line',
        data: data_js,
        options:
            {
            scales:
                {
                    A:{
                        type: 'linear',
                        position: 'left',
                        ticks: {
                            color: border_color[0]
                        }
                    },
                    B:{
                        type: 'linear',
                        position: 'right',
                        ticks: {
                            color: border_color[1]
                        }
                    },
                    C:{
                        type: 'linear',
                        position: 'right',
                        ticks: {
                            max: 1,
                            min: 0,
                            color: border_color[2]
                        }
                    }
                }
             }
    });
    return myChart;
}
/**
 *
 * @param canva
 * @param data
 * @param ordonnees
 * @param title
 * @param calc
 * @param colors
 * @param aliasUser
 * @returns {*}
 */
export function doughnut(canva, data, ordonnees, title, calc, colors, aliasUser){
    var labels = [];
    var datas = [];
    var total = 0;
    data.forEach((el)=>{
        if(el.Value.$numberDecimal !== undefined){
            datas.push(el.Value.$numberDecimal);
            total += Number.parseFloat(el.Value.$numberDecimal);
        }else{
            datas.push(el.Value);
            total += Number.parseFloat(el.Value);
        }
        // datas.push(el.Value.$numberDecimal);
        // labels.push(el._id);
        labels.push(aliasUser.alias_labels[el._id]);
    });
    // var data_js = {
    //     labels: labels,
    //     datasets: [{
    //         data:datas,
    //         backgroundColor: colors.back_color,
    //         borderColor: colors.border_color,
    //         borderWidth: 1
    //     }]
    // }
    var data_js = {
        labels: labels,
        datasets: [{
            data:datas,
            backgroundColor: (p)=>{
                return filterColors(p, colors, total, labels).back_color;
            },
            borderColor: (p)=>{
                return filterColors(p, colors, total, labels).border_color;
            },
            borderWidth: 1
        }]
    }
    const ctx = document.getElementById(canva);
    const myChart = new Chart(ctx, {
        type: 'doughnut',
        data: data_js,
        options: {
            // responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    position: 'top',
                    display : false
                },
                title: {
                    display: true,
                    text: 'Chart.js Doughnut Chart'
                }
            }
        },
    });
    return myChart;
}

/**
 *
 * @param canva
 * @param data
 * @param ordonnees
 * @param title
 * @param colors
 * @param aliasUser
 * @returns {*}
 */
export function pie(canva, data, ordonnees, title, colors, aliasUser){
    var labels = [];
    var datas = [];
    var total = 0;
    data.forEach((el)=>{
        if(el.Value.$numberDecimal !== undefined){
            datas.push(el.Value.$numberDecimal);
            total += Number.parseFloat(el.Value.$numberDecimal);
        }else{
            datas.push(el.Value);
            total += Number.parseFloat(el.Value);
        }
        // datas.push(el.Value.$numberDecimal);
        // labels.push(el._id);
        labels.push(aliasUser.alias_labels[el._id]);
    });
    // var data_js = {
    //     labels: labels,
    //     datasets: [{
    //         label: ordonnees,
    //         data:datas,
    //         backgroundColor: colors.back_color,
    //         borderColor: colors.border_color,
    //         borderWidth: 1
    //     }]
    // }
    var data_js = {
        labels: labels,
        datasets: [{
            label: ordonnees,
            data:datas,
            backgroundColor: (p)=>{
                return filterColors(p, colors, total, labels).back_color;
            },
            borderColor: (p)=>{
                return filterColors(p, colors, total, labels).border_color;
            },
            borderWidth: 1
        }]
    }
    const ctx = document.getElementById(canva);
    const myChart = new Chart(ctx, {
        type: 'pie',
        data: data_js,
        options: {
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    position: 'top',
                },
                title: {
                    display: true,
                    text: 'Chart.js Pie Chart'
                }
            }
        },
    });
    return myChart;
}

/**
 *
 * @param canva
 * @param data
 * @param ordonnees
 * @param title
 * @param calc
 * @param colors
 * @param aliasUser
 * @returns {*}
 */
export function multiPie(canva, data, ordonnees, title, calc, colors, aliasUser){
    var dataClean = volt.dataFilter(data);
    var labelMulti = [];
    let labTab = [];
    var datasets = [{
        data: dataClean.totaux,
        borderColor: colors.border_color,
        backgroundColor:colors.back_color,
        borderWidth: 1
    }];
    dataClean.legends.forEach((item, i)=>{
        datasets.push(
            {
                data: dataClean.values[i],
                borderColor: colors.border_color,
                backgroundColor:colors.back_color,
                borderWidth: 1
            }
        );
        dataClean.labels.forEach((el)=>{
            labelMulti.push(aliasUser.alias_labels[el]+'~'+aliasUser.alias_legends[item]);
        })
    });
    dataClean.labels.forEach((el)=>{
        labTab.push(aliasUser.alias_labels[el]);
    });
    var data_js = {
        // labels: dataClean.labels.concat(labelMulti),
        labels: labTab.concat(labelMulti),
        datasets: datasets
    };
    const ctx = document.getElementById(canva);
    const myChart = new Chart(ctx, {
        type: 'pie',
        data: data_js,
        options: {
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    // display : false
                    // labels : {dataClean.labels}
                    labels: {
                        generateLabels: function(chart) {
                            var labelsOriginal = []
                            dataClean.labels.forEach((lab, i)=>{
                                labelsOriginal.push({
                                    text : aliasUser.alias_labels[lab],
                                    fillStyle : colors.back_color[i],
                                    strokeStyle : colors.border_color[i],
                                    lineWidth : 1
                                })
                            })
                            // // Get the default label list
                            // const original = Chart.overrides.pie.plugins.legend.labels.generateLabels;
                            // const labelsOriginal = original.call(this, chart);
                            //
                            // // Build an array of colors used in the datasets of the chart
                            // let datasetColors = chart.data.datasets.map(function(e) {
                            //     return e.backgroundColor;
                            // });
                            // datasetColors = datasetColors.flat();
                            //
                            // // Modify the color and hide state of each label
                            // labelsOriginal.forEach(label => {
                            //     // There are twice as many labels as there are datasets. This converts the label index into the corresponding dataset index
                            //     label.datasetIndex = (label.index - label.index % 2) / 2;
                            //
                            //     // The hidden state must match the dataset's hidden state
                            //     label.hidden = !chart.isDatasetVisible(label.datasetIndex);
                            //
                            //     // Change the color to match the dataset
                            //     label.fillStyle = datasetColors[label.index];
                            // });
                            return labelsOriginal;
                        }
                    },
                },
                tooltip: {
                    callbacks: {
                        label: function(context) {
                            const labelIndex = (context.datasetIndex * dataClean.labels.length) + context.dataIndex;
                            return context.chart.data.labels[labelIndex] + ': ' + context.formattedValue;
                        }
                    }
                }
            }
        },
    });
    return myChart;
}

function filterColors(p, colors, total, labels = []){
    var colorsFil = {};
    colorsFil.back_color = [];
    colorsFil.border_color = [];
    if(colors.back_color !== undefined){
        if(labels.length > 0) {
            labels.forEach((label, i) => {
                if (colors.back_color[label] !== undefined) {
                    colorsFil.back_color.push(colors.back_color[label]);
                } else {
                    colorsFil.back_color.push(colors.back_color[i]);
                }
                if (colors.border_color[label] !== undefined) {
                    colorsFil.border_color.push(colors.border_color[label]);
                } else {
                    colorsFil.border_color.push(colors.border_color[i]);
                }
            })
        }else{
            for(const color in colors.back_color){
                // console.log(color);
                colorsFil.back_color.push(colors.back_color[color]);
            }
            for(const color in colors.border_color){
                // console.log(color);
                colorsFil.border_color.push(colors.border_color[color]);
            }
        }
    }else{
        colors.forEach((col)=>{
            var perCent = 0;
            if(p.raw !== undefined){
                perCent = p.raw * 100 / total
            }else if(p.dataset.data[p.index] !== undefined){
                perCent = p.dataset.data[p.index] * 100 / total
            }
            if(perCent > col.value_min && perCent <= col.value_max){
                colorsFil.back_color.push(col.back_color);
                colorsFil.border_color.push(col.border_color);
            }
        })
    }
    return colorsFil;
}








// /**
//  *
//  * @param array
//  * @returns {{min: number, max: number}}
//  */
// function min_max(array){
//     var min = 0;
//     var max = 0;
//     array.forEach((el, index)=>{
//         if(el.Value !== undefined){
//             if(el.Value.$numberDecimal !== undefined){
//                 if(index === 0){
//                     min = Number.parseFloat(el.Value.$numberDecimal);
//                     max = Number.parseFloat(el.Value.$numberDecimal);
//                 }else{
//                     if(Number.parseFloat(el.Value.$numberDecimal) < min)min=Number.parseFloat(el.Value.$numberDecimal);
//                     if(Number.parseFloat(el.Value.$numberDecimal) > max)max=Number.parseFloat(el.Value.$numberDecimal);
//                 }
//             }else{
//                 if(index === 0){
//                     min = Number.parseFloat(el.Value);
//                     max = Number.parseFloat(el.Value);
//                 }else{
//                     if(Number.parseFloat(el.Value) < min)min=Number.parseFloat(el.Value);
//                     if(Number.parseFloat(el.Value) > max)max=Number.parseFloat(el.Value);
//                 }
//             }
//             // if(index === 0){
//             //     min = Number.parseFloat(el.Value.$numberDecimal);
//             //     max = Number.parseFloat(el.Value.$numberDecimal);
//             // }else{
//             //     if(Number.parseFloat(el.Value.$numberDecimal) < min)min=Number.parseFloat(el.Value.$numberDecimal);
//             //     if(Number.parseFloat(el.Value.$numberDecimal) > max)max=Number.parseFloat(el.Value.$numberDecimal);
//             // }
//         }else{
//             if(index === 0){
//                 min = Number.parseFloat(el);
//                 max = Number.parseFloat(el);
//             }else{
//                 if(Number.parseFloat(el) < min)min=Number.parseFloat(el);
//                 if(Number.parseFloat(el) > max)max=Number.parseFloat(el);
//             }
//         }
//     })
//     return {min: min, max: max};
// }



// /**
//  *
//  * @param data
//  * @returns {{legends: (*|*[]), values: *[], labels: *[], totaux: *[]}}
//  */
// function dataFilter(data){
//     var labels = [];
//     var legends = [];
//     var leg = [];
//     var totaux = [];
//     var totauxGen = [];
//     data.forEach((el, a)=>{
//         leg[a] = []
//         labels.push(el._id);
//         if(el.totalGeneral.$numberDecimal !== undefined){
//             totauxGen.push(parseFloat(el.totalGeneral.$numberDecimal));
//         }else{
//             totauxGen.push(parseFloat(el.totalGeneral));
//         }
//         // totauxGen.push(parseFloat(el.totalGeneral.$numberDecimal));
//         el.Value.forEach((el_tmp, i)=>{
//             leg[a][i] = [];
//             for(var id in el_tmp){
//                 if(id !== 'total')leg[a][i].push(el_tmp[id]);
//             }
//         })
//     });
//     var leg2 = [];
//     leg.forEach((el, i)=>{
//         leg2[i] = [];
//         el.forEach((el_tmp, ii)=>{
//             el_tmp.forEach((el2, iii)=>{
//                 leg2[i][iii] = [];
//             });
//         })
//         el.forEach((el_tmp, ii)=>{
//             el_tmp.forEach((el2, iii)=>{
//                 leg2[i][iii].push(el2);
//             });
//         })
//     });
//     leg2.forEach((tab, i)=>{
//         tab.forEach((tab2, ii)=>{
//             legends = Array.from(new Set(legends.concat(tab2)));
//         });
//     });
//     legends = volt.filterArray(legends);
//     legends.forEach((legend, index)=>{
//         totaux[index] = [];
//         data.forEach((el, ind)=>{
//             totaux[index][ind] = 0;
//             el.Value.forEach((el_tmp)=>{
//                 for(var id in el_tmp) {
//                     if(el_tmp.total.$numberDecimal !== undefined){
//                         if(el_tmp[id] === legend) totaux[index][ind] += Number(el_tmp.total.$numberDecimal);
//                     }else{
//                         if(el_tmp[id] === legend) totaux[index][ind] += Number(el_tmp.total);
//                     }
//                     // if(el_tmp[id] === legend) totaux[index][ind] += Number(el_tmp.total.$numberDecimal);
//                 }
//             })
//         });
//     });
//     return {labels: labels, legends: legends ,values : totaux, totaux: totauxGen};
// }

// /**
//  *
//  * @param data
//  * @returns {{legends: (*|*[]), scales: *[], values: *[], labels: (*|*[]), totaux: *[]}}
//  */
// function dataFilterMulti(data){
//     var labels = [];
//     var legends = [];
//     var leg = [];
//     var totaux = [];
//     var totauxGen = [];
//     var scales = [];
//     data.forEach((ele, aa)=>{
//         scales.push(ele.label);
//         leg[aa] = []
//         ele.data.forEach((el,a)=>{
//             leg[aa][a] = []
//             labels.push(el._id);
//             if(el.totalGeneral.$numberDecimal !== undefined){
//                 totauxGen.push(parseFloat(el.totalGeneral.$numberDecimal));
//             }else{
//                 totauxGen.push(parseFloat(el.totalGeneral));
//             }
//             // totauxGen.push(parseFloat(el.totalGeneral.$numberDecimal));
//             el.Value.forEach((el_tmp, i)=>{
//                 leg[aa][a][i] = [];
//                 for(var id in el_tmp){
//                     if(id !== 'total')leg[aa][a][i].push(el_tmp[id]);
//                 }
//             })
//         })
//     });
//     var leg2 = [];
//     leg.forEach((ele, a)=>{
//         leg2[a] = [];
//         ele.forEach((el, i)=> {
//             leg2[a][i] = [];
//             el.forEach((el_tmp, ii) => {
//                 el_tmp.forEach((el2, iii) => {
//                     leg2[a][i][iii] = [];
//                 });
//             })
//             el.forEach((el_tmp, ii) => {
//                 el_tmp.forEach((el2, iii) => {
//                     leg2[a][i][iii].push(el2);
//                 });
//             })
//         });
//     });
//     leg2.forEach((tabi, a)=>{
//         tabi.forEach((tab, i)=>{
//             tab.forEach((tab2, ii)=>{
//                 legends = Array.from(new Set(legends.concat(tab2)));
//             });
//         });
//     });
//     legends = volt.filterArray(legends);
//     labels = volt.filterArray(labels);
//     legends.forEach((legend, index)=>{
//         totaux[index] = [];
//         data.forEach((ele, i)=>{
//             totaux[index][i] = [];
//             ele.data.forEach((el, ind)=>{
//                 totaux[index][i][ind] = 0;
//                 el.Value.forEach((el_tmp)=>{
//                     for(var id in el_tmp) {
//                         if(el_tmp.total.$numberDecimal !== undefined){
//                             if(el_tmp[id] === legend) totaux[index][i][ind] += Number(el_tmp.total.$numberDecimal);
//                         }else{
//                             if(el_tmp[id] === legend) totaux[index][i][ind] += Number(el_tmp.total);
//                         }
//                         // if(el_tmp[id] === legend) totaux[index][i][ind] += Number(el_tmp.total.$numberDecimal);
//                     }
//                 })
//             });
//         });
//     });
//     return {labels: labels, legends: legends,scales:scales ,values : totaux, totaux: totauxGen};
// }
