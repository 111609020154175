<template>
    <div class="btns-choice-admin-div">
        <div class="div-btn-params-admin">
            <h4>Paramètres de contrôle</h4>
            <div class="btns-params-admin">
                <select class="btn-choice-admin" id="select-admin-year" @change="callYear">
                    <option v-for="annee in annees" :key="annee" :value="annee">{{ annee }}</option>
                </select>
                <select class="btn-choice-admin" id="select-admin-entity" @change="callUsers">
                    <option value="0||all_entities">Communes</option>
                    <option v-for="entity in entities" :key="entity.entity" :value="entity.id+'||'+entity.entity">
                        {{ entity.entity }}
                    </option>
                </select>
                <select class="btn-choice-admin" id="select-admin-user" @change="callUserDetails">
                    <option value="all">Utilisateurs</option>
                    <option v-for="user in users" :key="user" :value="user._id">{{
                            user.firstName + ' ' + user.lastName
                        }}
                    </option>
                </select>
            </div>
        </div>
        <div v-if="entity_selected !== 'all_entities'" class="div-btn-params-admin">
            <h4>Période de contrôle</h4>
            <div class="btns-params-admin">
                <select class="btn-choice-admin" id="select-admin-length" @change="changeLengthPeriod">
                    <option v-for="length in Object.keys(length_tab)" :key="length" :value="length_tab[length]">{{
                            length
                        }}
                    </option>
                </select>
                <select class="btn-choice-admin" id="select-admin-periods" @change="changePeriod">
                    <option v-for="period in Object.keys(periods)" :key="period" :value="periods[period]">{{
                            period
                        }}
                    </option>
                </select>
            </div>
        </div>
        <div v-if="entity_selected !== 'all_entities'" class="div-btn-params-admin">
            <h4>Délai de rafraichissement</h4>
            <div class="btns-params-admin">
                <select class="btn-choice-admin" id="select-admin-laps" @change="changeLaps">
                    <option v-for="lap in Object.keys(laps_tab)" :key="lap" :value="laps_tab[lap]">{{
                            lap
                        }}
                    </option>
                </select>
            </div>
        </div>
        <!--    <button class="btn-choice-admin" @click="callProps">Propriétés</button>-->
    </div>
    <div v-if="entity_selected_id === '' || entity_selected_id === '0' || entity_selected_id === 0"
         id="kpis-admin-content" class="admin-content-div">
        <KpiComponentCO :id="'kpi-component-admin'"
                        backgroundColor="#fefefe"
                        color="#555555"
                        title="ACTIVITES PAR COMMUNE"
                        :kpis="analytics_commune"
                        gridCS="1"
                        gridCE="21"
                        gridRS="3"
                        gridRE="10"
                        gridSize="1"
                        :entity="entity_selected"
                        :year="year"
                        :map_filter="map_filter"
                        :admin=1
        />
        <KpiComponentCO :id="'kpi-component-admin'"
                        backgroundColor="#fefefe"
                        color="#555555"
                        title="DETAILS DES ACTIVITES PAR COMMUNE ET PAR UTILISATEUR"
                        :kpis="analytics"
                        gridCS="1"
                        gridCE="21"
                        gridRS="3"
                        gridRE="10"
                        gridSize="4"
                        :entity="entity_selected"
                        :year="year"
                        :map_filter="map_filter"
                        :admin=1
        />
    </div>
    <div v-else id="kpis-admin-content" class="admin-content-div">
        <KpiComponentCO :id="'kpi-component-admin'"
                        backgroundColor="#fefefe"
                        color="#555555"
                        title="CONNECTIONS EN TEMPS REEL"
                        :kpis="analytics_actions"
                        gridCS="1"
                        gridCE="15"
                        gridRS="3"
                        gridRE="10"
                        gridSize="1"
                        :entity="entity_selected"
                        :year="year"
                        :map_filter="map_filter"
                        :admin=1
        />
        <KpiComponentCO :id="'kpi-component-admin'"
                        backgroundColor="#fefefe"
                        color="#555555"
                        title="LOGS EN TEMPS REEL"
                        :kpis="analytics_logs"
                        gridCS="15"
                        gridCE="21"
                        gridRS="3"
                        gridRE="20"
                        gridSize="1"
                        :entity="entity_selected"
                        :year="year"
                        :map_filter="map_filter"
                        :admin=1
        />
        <KpiComponentCO :id="'kpi-component-admin'"
                        backgroundColor="#fefefe"
                        color="#555555"
                        title="DETAILS DES ACTIVITES PAR COMMUNE ET PAR UTILISATEUR"
                        :kpis="analytics"
                        gridCS="1"
                        gridCE="21"
                        gridRS="3"
                        gridRE="10"
                        gridSize="4"
                        :entity="entity_selected"
                        :year="year"
                        :map_filter="map_filter"
                        :admin=1
        />
    </div>
</template>

<script>
import admin from "@/services/admin";
import volt from "@/volt";
import KpiComponentCO from "@/components/KpiComponentCO";
// import * as charts_obs from "@/charts_obs";
export default {
    name: "adminBoard",
    props: {
        user: Object,
        env: Object,
        entities: Object,
        year: String,
        map_filter: Number
    },
    components: {
        KpiComponentCO
    },
    data() {
        return {
            users: [],
            entity_selected: 'all_entities',
            entity_selected_id: '',
            user_selected: {},
            year_selected: '2024',
            details_connexion: {},
            analytics: [],
            analytics_commune: [],
            analytics_logs: [],
            analytics_actions: [],
            annees: ['2024'],
            interval: '',
            laps: 600000,
            period: 'day',
            length_period: 1,
            periods: {'Jour': 'day', 'Semaine': 'week', 'Mois': 'month', 'Année': 'year'},
            laps_tab: {"1 min": 30000, "5 min": 300000, "10 min": 600000, "30 min": 1800000, "1 heure": 3600000},
            length_tab: {"1": 1, "2": 2, "3": 3, "4": 4, "5": 5, "6": 6, "7": 7, "8": 8, "9": 9, "10": 10}
        }
    },
    async mounted() {
        await this.mounted_kpis();
        console.log(this.entity_selected)
        // await this.refresh_int();
        // this.interval = setInterval(()=> {
        //   this.refresh_int();
        // }, 10000);
    },
    unmounted() {
        console.log('unmounted');
        clearInterval(this.interval);
    },
    methods: {
        changeLengthPeriod(e) {
            this.length_period = e.target.value;
            this.refresh_int();
        },
        changePeriod(e) {
            this.period = e.target.value;
            this.refresh_int();
        },
        changeLaps(e) {
            clearInterval(this.interval);
            this.interval = setInterval(() => {
                this.refresh_int();
            }, this.laps);
            this.laps = e.target.value;
            this.refresh_int();
        },
        async refresh_int() {
            const actions = await admin.getLastActions(this.entity_selected, this.period, this.length_period);
            let logs = {
                title: "Logs",
                style: {visuel: "logs"},
                details: [],
                gridCS: "1",
                gridCE: "13",
                size: "12"
            }
            actions.logs.forEach((log) => {
                logs.details.push({
                    title: log.route,
                    value: "",
                    date: log.date,
                    name: log.first_name[0] + ' ' + log.last_name[0],
                    link: "",
                    style: {backgroundColor: "#fefefe", color: "#555555", gridCS: "1", gridCE: "21", visuel: "jauge"},
                    evolution: 0
                })
            })
            this.analytics_logs = [logs];
            let analytic = {
                title: "Actions",
                style: {visuel: "histo-horizontal"},
                details: [],
                gridCS: "1",
                gridCE: "13",
                size: "12"
            }
            actions.analytic.forEach((action) => {
                analytic.details.push({
                    title: action['first_name'][0] + ' ' + action['last_name'][0],
                    value: action['count'],
                    link: "",
                    style: {backgroundColor: "#fefefe", color: "#555555", gridCS: "1", gridCE: "21", visuel: "jauge"},
                    evolution: 0
                })
            })
            volt.taks('.histo-connexion-admin', '', false).forEach((histo) => {
                histo.remove();
            });
            this.analytics_actions = [analytic];
            await this.mounted_kpis(this.entity_selected);
        },
        async mounted_kpis(_entity) {
            this.analytics = [];
            this.analytics_commune = [];
            volt.taks('.histo-connexion-admin', '', false).forEach((histo) => {
                histo.remove();
            });
            if (_entity === undefined || _entity === 'all' || _entity === '' || _entity === 0 || _entity === '0') {
                _entity = "all_entities";
            }
            admin.getUsers(_entity, this.year_selected).then((response) => {
                this.details_connexion = [];
                this.communes_connexion = [];
                response.forEach((details) => {
                    if (details._id.entity[1] === undefined) {
                        if (this.details_connexion[details._id.entity] === undefined) this.details_connexion[details._id.entity] = {};
                        if (this.communes_connexion[details._id.entity] === undefined) this.communes_connexion[details._id.entity] = 0;
                        if (this.details_connexion[details._id.entity][details._id.user] === undefined) this.details_connexion[details._id.entity][details._id.user] = {};
                        this.details_connexion[details._id.entity][details._id.user]['name'] = volt.onlyFirstToUpper(details.first_name[0]) + ' ' + volt.onlyFirstToUpper(details.last_name[0]);
                        this.details_connexion[details._id.entity][details._id.user]['count'] = details.count;
                        this.communes_connexion[details._id.entity] += details.count;
                    }
                })
                for (let entity in this.details_connexion) {
                    let analytic = {
                        title: entity,
                        style: {visuel: "histo-horizontal"},
                        details: [],
                        gridCS: "1",
                        gridCE: "13",
                        size: "12"
                    }
                    for (let user in this.details_connexion[entity]) {
                        analytic.details.push({
                            title: this.details_connexion[entity][user]['name'],
                            value: this.details_connexion[entity][user]['count'],
                            link: entity,
                            style: {
                                backgroundColor: "#fefefe",
                                color: "#555555",
                                gridCS: "1",
                                gridCE: "21",
                                visuel: "jauge"
                            },
                            evolution: 0
                        })
                    }
                    this.analytics.push(analytic);
                }
                let total_com_click = 0;
                Object.keys(this.communes_connexion).forEach((com) => {
                    total_com_click += this.communes_connexion[com];
                });
                if (Object.keys(this.communes_connexion).length > 20) {
                    let count_tab = 0;
                    Object.keys(this.communes_connexion).forEach((com, i) => {
                        if (i % 20 === 0) {
                            if (i > 0) count_tab++;
                            this.analytics_commune.push({
                                title: Object.keys(this.communes_connexion)[i] + " - " + (Object.keys(this.communes_connexion)[(i + 20)] ? Object.keys(this.communes_connexion)[(i + 20)] : Object.keys(this.communes_connexion)[Object.keys(this.communes_connexion).length - 1]),
                                total: total_com_click,
                                style: {visuel: "histo"},
                                details: [],
                                gridCS: "1",
                                gridCE: "13",
                                size: "12"
                            })
                            this.analytics_commune[count_tab].details.push({
                                title: com,
                                value: this.communes_connexion[com],
                                link: com,
                                style: {
                                    backgroundColor: "#fefefe",
                                    color: "#555555",
                                    gridCS: "1",
                                    gridCE: "21",
                                    visuel: "jauge"
                                },
                                evolution: 0
                            })
                        } else {
                            this.analytics_commune[count_tab].details.push({
                                title: com,
                                value: this.communes_connexion[com],
                                link: com,
                                style: {
                                    backgroundColor: "#fefefe",
                                    color: "#555555",
                                    gridCS: "1",
                                    gridCE: "21",
                                    visuel: "jauge"
                                },
                                evolution: 0
                            })
                        }
                    });
                } else {
                    this.analytics_commune = [{
                        title: "Total",
                        style: {visuel: "histo"},
                        details: [],
                        gridCS: "1",
                        gridCE: "13",
                        size: "12"
                    }]
                    for (let entity in this.communes_connexion) {
                        this.analytics_commune[0].details.push({
                            title: entity,
                            value: this.communes_connexion[entity],
                            link: entity,
                            style: {
                                backgroundColor: "#fefefe",
                                color: "#555555",
                                gridCS: "1",
                                gridCE: "21",
                                visuel: "jauge"
                            },
                            evolution: 0
                        })
                    }
                    console.log(this.analytics_commune);
                }
            })
        },
        async callUsers(e) {
            if (volt.tak('select-admin-year').value === undefined) {
                this.year_selected = '2024';
            } else {
                this.year_selected = volt.tak('select-admin-year').value;
            }
            this.entity_selected = e.target.value.split('||')[1];
            this.entity_selected_id = e.target.value.split('||')[0];
            console.log(this.entity_selected)
            if (this.entity_selected !== 'all_entities') {
                await this.refresh_int();
                const usersTab = await admin.getUsersEntity(this.entity_selected_id, this.entity_selected, this.year_selected)
                this.users = usersTab['users'];
                this.analytics.value = usersTab['analytic'];
                this.interval = setInterval(() => {
                    this.refresh_int();
                }, this.laps);
            } else {
                clearInterval(this.interval);
                this.users = [];
                this.analytics.value = [];
            }
            await this.mounted_kpis(this.entity_selected);
        },
        async callYear(e) {
            if (e.target.value === undefined) {
                this.year_selected = '2024';
            } else {
                this.year_selected = e.target.value;
            }
            this.entity_selected = volt.tak('select-admin-entity').value.split('||')[1];
            this.entity_selected_id = volt.tak('select-admin-entity').value.split('||')[0];
            const usersTab = await admin.getUsersEntity(this.entity_selected_id, this.entity_selected, this.year_selected)
            this.users = usersTab['users'];
            this.analytics.value = usersTab['analytic'];
        },
        async callUserDetails(e) {
            this.entity_selected = volt.tak('select-admin-entity').value.split('||')[1];
            this.entity_selected_id = volt.tak('select-admin-entity').value.split('||')[0];
            this.user_selected = await admin.getUserDetails(this.entity_selected_id, e.target.value, this.entity_selected, this.year_selected)
        },
        callProps() {
            console.log(this.user);
            console.log(this.env);
            console.log(this.entities);
            console.log(this.year);
            console.log(admin);
            console.log(volt);
        }
    }
}
</script>

<style scoped>
.btns-params-admin {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
}
.div-btn-params-admin {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    border: solid 1px #55555555;
    margin: 5px;
    padding: 10px;
    border-radius: 5px;
}
h4 {
    font-size: 16px;
    font-weight: bold;
    margin: 2px;
}

.admin-content-div {
    grid-column-start: 1;
    grid-column-end: 21;
    grid-row-start: 3;
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-gap: 1px;
    padding: 20px;
    height: 100%;
    max-height: 100%;
    background-color: #fefefe;
}

.btns-choice-admin-div {
    position: sticky;
    top: -5px;
    background-color: #fefefe;
    grid-column-start: 1;
    grid-column-end: 21;
    display: flex;
    flex-direction: row;
    justify-content: end;
    flex-wrap: wrap;
    margin-right: 15px;
}

.btn-choice-admin {
    z-index: 1000;
    width: 150px;
    background-color: #d6d6d6;
    color: #343434;
    border: none;
    padding: 5px 10px;
    text-align: start;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
    transition: 0.3s;
}

@media (max-width: 800px) {
    .btns-choice-admin-div {
        position: relative;
    }
    .div-btn-params-admin {
        width: 100%;
    }
    .btn-choice-admin {
        max-width: 50%;
        font-size: 10px;
        padding: 5px;
    }
    h4 {
        font-size: 12px;
    }
}
</style>