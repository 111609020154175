<template>
  <div class="jauge-radius" @click="callList">
    <div class="kpi-subtitle" :style="style.title">{{title}}</div>
    <div class="gauge gauge--liveupdate" :id="'gauge-'+title">
      <div class="gauge__container">
        <div class="gauge__marker"></div>
        <div class="gauge__background"></div>
        <div class="gauge__center"></div>
        <div class="gauge__data"></div>
        <div class="gauge__needle"></div>
      </div>
      <div class="gauge__labels mdl-typography__headline">
        <span class="gauge__label--low">0 %</span>
        <span class="gauge__label--spacer"><div class="gauge__value">{{value.toString().replace('.',',') +' %'}}</div></span>
        <span class="gauge__label--high">100 %</span>
      </div>
    </div>
  </div>
</template>

<script>
import volt from "@/volt";

export default {
  name: "JaugeRadius",
  emits: ["callLinkPri", "callLinkSec"],
  props: {
    limit : Number,
    title : String,
    kpis : [Array],
    value : [String, Number],
    style : Object,
    link : Object,
    size : String,
    year : String
  },
  mounted() {
    let gauge = this.Gauge(document.getElementById("gauge-"+this.title));
    gauge.value(Number(this.value) / 100);
    // gauge.value(0.99);
  },
  methods:{
    callList(){
      if(this.link.name) {
        volt.list = {
          name: this.link.name,
          value: this.link.value,
          size: this.link.size,
          type: this.link.type,
          limit: this.limit,
          offset: 0,
          order: 1,
          by: ''
        };
        this.$emit('callLinkPri');
      }
    },
    Gauge(el) {

      // ##### Private Properties and Attributes

      var element,      // Containing element for the info component
          data,         // `.gauge__data` element
          needle,
          // val,// `.gauge__needle` element
          value = 0.0,  // Current gauge value from 0 to 1
          prop;         // Style for transform

      // ##### Private Methods and Functions

      var setElement = function(el) {
        // Keep a reference to the various elements and sub-elements
        element = el;
        data = element.querySelector(".gauge__data");
        needle = element.querySelector(".gauge__needle");
        // val = element.querySelector(".gauge__value");
      };

      var setValue = function(x) {
        value = x;
        var turns = -0.5 + (x * 0.5);
        data.style[prop] = "rotate(" + turns + "turn)";
        needle.style[prop] = "rotate(" + turns + "turn)";
        // val.style[prop] = "rotate(0.25turn)";
      };

      // ##### Object to be Returned

      function exports() { }

      // ##### Public API Methods

      exports.element = function(el) {
        if (!arguments.length) { return element; }
        setElement(el);
        return this;
      };

      exports.value = function(x) {
        if (!arguments.length) { return value; }
        setValue(x);
        return this;
      };

      // ##### Initialization

      var body = document.getElementsByTagName("body")[0];
      ["webkitTransform", "mozTransform", "msTransform", "oTransform", "transform"].
      forEach(function(p) {
            if (typeof body.style[p] !== "undefined") { prop = p; }
          }
      );

      if (arguments.length) {
        setElement(el);
      }

      return exports;

    }
  }
}
</script>

<style scoped>
.jauge-radius{
  height: 100%;
  overflow: hidden;
}
.kpi-subtitle{
  text-align: start;
  font-size: 14px;
  font-weight: bold;
  padding: 15px;
  display: flex;
  flex-direction: row;
  height: 10%;
}
.gauge {
  position: relative;
}

.gauge__container {
  margin: 0;
  padding: 0;
  position: absolute;
  left: 50%;
  overflow: hidden;
  text-align: center;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.gauge__background {
  z-index: 0;
  position: absolute;
  /*background-color: #54edda;*/
  background: linear-gradient(to left, #70b942 10%, #f3a01b 50% , #e00505 90%);
  top: 0;
  border-radius: 300px 300px 0 0;
}

.gauge__data {
  z-index: 1;
  position: absolute;
  background-color: transparent;
  margin-left: auto;
  margin-right: auto;
  border-radius: 300px 300px 0 0;
  -webkit-transform-origin: center bottom;
  -moz-transform-origin: center bottom;
  -ms-transform-origin: center bottom;
  -o-transform-origin: center bottom;
  transform-origin: center bottom;
}
.gauge__value{
  position: relative;
  /*left: 70px;*/
  top: -3px;
  border-radius: 10px;
  font-weight: bold;
  text-align: center;
  padding: 5px;
  background-color: #F4AF0A66;
}
.gauge__center {
  z-index: 2;
  position: absolute;
  background-color: #fff;
  margin-right: auto;
  border-radius: 300px 300px 0 0;
}

.gauge__marker {
  z-index: 3;
  background-color: #fff;
  position: absolute;
  width: 1px;
}

.gauge__needle {
  z-index: 4;
  background-color: #F4AF0A;
  height: 4px;
  box-shadow: 1px 0.5px 5px #555555;
  position: absolute;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -ms-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.gauge__labels {
  display: table;
  margin: 0 auto;
  position: relative;
}

.gauge__label--low {
  display: table-cell;
  text-align: center;
}

.gauge__label--spacer {
  /*display: table-cell;*/
  display: flex;
  justify-content: center;
}

.gauge__label--high {
  display: table-cell;
  text-align: center;
}

/*
 * Now define the rules that depend on the size of
 * the gauge. We start with sizing for a small mobile
 * device.
 */

.gauge { height: calc(120px + 3em); }
.gauge__container { width: 240px; height: 120px; }
.gauge__marker { height: 120px; left: 119.5px; }
.gauge__background { width: 240px; height: 120px; }
.gauge__center { width: 144px; height: 72px; top: 48px; margin-left: 48px; }
.gauge__data { width: 240px; height: 120px; }
.gauge__needle { left: 120px; top: 117px; width: 90px; }
.gauge__labels { top: 120px; width: 240px; }
.gauge__label--low { width: 48px; }
.gauge__label--spacer { width: 144px; }
.gauge__label--high { width: 48px; }

</style>