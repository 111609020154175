import { createApp } from 'vue'
import App from './App.vue'
import volt from './volt'

console.log('V-01.00.01');
if(volt.getParameterUrl().id_token){
    let token = volt.getParameterUrl().id_token;
    let token1 = token.replace(/equalium/g,"=");
    let token2 = token1.replace(/questalium/g,"?");
    let token3 = token2.replace(/slashalium/g,"/");
    let token4 = token3.replace(/antislashalium/g,"\\");
    let token5 = token4.replace(/andalium/g,"&");
    sessionStorage.setItem('x-token', token5);
    sessionStorage.setItem('x-entity', decodeURI(volt.getParameterUrl().entity));
    volt.getEnv().then((env)=>{
        window.location.href = env.paths.front;
    });

    // createApp(App, {entity_connect :  volt.getParameterUrl().entity, id_token : volt.getParameterUrl().id_token}).mount('#app')
}else if(volt.getParameterUrl().token_jwk){
    createApp(App,{token_new_user : volt.getParameterUrl().token_jwk}).mount('#app')
}else if(volt.getParameterUrl().token){
    createApp(App,{token : volt.getParameterUrl().token}).mount('#app')
}else if(volt.getParameterUrl().recover){
    createApp(App,{recover : volt.getParameterUrl().recover}).mount('#app')
}else if(volt.getParameterUrl().recover_mdp){
    createApp(App,{recover_mdp : volt.getParameterUrl().recover_mdp}).mount('#app')
} else{
    createApp(App).mount('#app')
}
if(screen.width < 1500){
    let browserZoomLevel = Math.round(window.devicePixelRatio * 100);
    if(browserZoomLevel > 80 ){
        alert('La résolution de votre écran risque de nuire à l\'expérience utilisateur de l\'application, pour que son utilisation soit optimale merci de régler le zoom de votre navigateur à 75%');
    }
}

