<template>
    <PageLoader/>
    <VideoPlayer v-if="videoView" @closeVideo="closeVideo"/>
    <PageError v-if="error_page" :reason="error_reason" @closeWindows="closeWindowsError"/>
    <PopAnnonce title="Annonce" :glossaire="glossaire" :annonce="annonce" :id_annonce="annonceId" :annonces="[]"
                color="#222222" @callList="callListPop" @callAnnonce="callAnnonce" :list="listPop" :limit="limit"
                :entity="entity" :user="user" @callAnnonceCluster="callAnnonceCluster" :id_cluster="id_cluster"
                :cluster="cluster" :year="year"/>
    <PopMenuChart @openVideo="openVideo" :list_class="list_class" :values="values" :entetes="entetes" :models="models"
                  title="Paramètres de la liste" :list_id="list_id" :annonce="annonce" color="#222222"
                  @callList="chargeList" @callAnnonce="callAnnonce" :list="list" :limit="limit" :year="year"
                  :entity="entity" :glossaire="glossaire" :user="user" :list_user="user_list"
                  :list_entity="entity_list"/>
    <PopConformites title="Mise à jour" :annonce="{}" :ids="ids" :annonces="annonces" color="#222222"
                    @callList="callListPop" @callAnnonce="callAnnonce" :list="listPop" :limit="limit" :entity="entity"
                    :user="user" :year="year"/>
    <div v-if="entity !== '' && x_token !== undefined && x_token !== '' && dashboard === 'observatoire'"
         class="base-grid">
        <div class="base-grid-menu">
            <NavBar
                :env="env"
                :user="user"
                :dashboard="dashboard"
                :logo="logo"
                :entities="entities_user"
                :entity_select="entity_select"
                :options="searchOptions"
                :clickKpi="clickKpi"
                :year="year"
                :limit="limit"
                :entity="entity"
                :specif="specif"
                :map_filter="map_filter"
                @callSearch="callSearch"
                @callYear="callYear"
                @callResult="callResult"
                @callChart="callChart"
                @callEntity="callEntity"
                @callMapFilter="callMapFilter"
                @resetListUser="resetListUser"
                @callAdminBoard="callAdminBoard"
            />
        </div>
        <div class="base-grid-kpi">
            <div v-if="Object.keys(feature).length > 0" class="title-kpi-components">{{ kpis.title }} - {{ year }}<i
                class="close-kpis-specif fas fa-times-circle" @click="refreshMap"></i></div>
            <div v-else class="title-kpi-components">{{ kpis.title }} - {{ year }}</div>
            <KpiComponent v-for="kpi in kpis.kpis" :key="kpi"
                          :id="'kpi-component-'+kpi.title"
                          backgroundColor="#fefefe"
                          color="#555555"
                          :gridCS="kpi.gridCS"
                          :gridCE="kpi.gridCE"
                          :gridRS="kpi.gridRS"
                          :gridRE="kpi.gridRE"
                          :open="kpi.display"
                          :title="kpi.title"
                          :kpis="kpi.details"
                          :limit="limit"
                          @callList="closeAndcallList"
                          @syncKpis="syncKpis"
            />
        </div>
        <div class="base-grid-map">
            <MapsVue v-if="Object.keys(list).length > 0"
                     title='ol'
                     gridCS="1"
                     gridCE="21"
                     gridRS="1"
                     gridRE="12"
                     @click="callMap"
            />
            <MapsVue v-else
                     title='ol'
                     gridCS="1"
                     gridCE="21"
                     gridRS="1"
                     gridRE="21"
                     @click="callMap"
            />
            <ListAnnonces v-if="Object.keys(list).length > 0"
                          :list=list
                          type='observatoire'
                          gridCS="1"
                          gridCE="21"
                          gridRS="12"
                          gridRE="21"
                          :limit="limit"
                          :specif="specif"
                          :cluster="cluster"
                          :id_cluster="id_cluster"
                          :id_annonce="annonceId"
                          @callList="callList"
                          @chargeList="chargeList"
                          @closeList="closeList"
                          @callAnnonce="callAnnonce"
                          @callAnnonceCluster="callAnnonceCluster"
                          @callAnnoncePlateforme="callAnnoncePlateforme"
                          @resetListUser="resetListUser"
                          @openVideo="openVideo"
                          :user_list="user_list"
                          :entity_list="entity_list"
                          :entity="entity"
                          :year="year"
                          :glossaire="glossaire"
                          :user="user"
            />
        </div>
    </div>
    <div v-else-if="entity !== '' && x_token !== undefined && x_token !== '' && dashboard === 'coupdoeil'"
         class="base-grid">
        <div class="base-grid-menu">
            <NavBar
                :env="env"
                :user="user"
                :dashboard="dashboard"
                :logo="logo"
                :entities="entities_user"
                :entity_select="entity_select"
                :options="searchOptions"
                :clickKpi="clickKpi"
                :year="year"
                :limit="limit"
                :entity="entity"
                :specif="specif"
                :map_filter="map_filter"
                @callSearch="callSearch"
                @callYear="callYear"
                @callResult="callResult"
                @callChart="callChart"
                @callEntity="callEntity"
                @callMapFilter="callMapFilter"
                @resetListUser="resetListUser"
                @callAdminBoard="callAdminBoard"
            />
        </div>
        <div class="base-grid-chart">
            <ListAnnonces v-if="Object.keys(list).length > 0"
                          :list=list
                          type='coupdoeil'
                          gridCS="1"
                          gridCE="21"
                          gridRS="1"
                          gridRE="21"
                          :limit="limit"
                          :specif="specif"
                          :cluster="cluster"
                          :id_cluster="id_cluster"
                          :id_annonce="annonceId"
                          @callList="callList"
                          @chargeList="chargeList"
                          @closeList="closeList"
                          @callAnnonce="callAnnonce"
                          @callAnnonceCluster="callAnnonceCluster"
                          @callAnnoncePlateforme="callAnnoncePlateforme"
                          @callMenuChart="callMenuChart"
                          @callConformites="callConformites"
                          @callMap="callMap"
                          @openMap="openMapKpi"
                          @resetListUser="resetListUser"
                          @openVideo="openVideo"
                          :user_list="user_list"
                          :entity_list="entity_list"
                          :entity="entity"
                          :year="year"
                          :glossaire="glossaire"
                          :ids="ids"
                          :user="user"
            />

            <div v-else class="base-grid-kpi-co" @scroll="callView('')">
                <div class="headers-kpi-intro-div" id="kpi-all-top-coupdoeil">
                    <div class="headers-kpi-intro-text">
                        <h4 class="headers-kpi-intro-title">Bienvenue dans le tableau de bord de votre "Observatoire des
                            locations meublées touristiques"</h4>
                        <p class="headers-kpi-intro-p">Vous trouverez ci-dessous la synthèse des données ELAN collectées
                            sur votre territoire</p>
                        <p class="headers-kpi-intro-p">En cliquant sur les indicateurs, vous pouvez accéder aux données
                            détaillées : carte et liste d’annonces correspondantes</p>
                    </div>
                    <div class="export-kpis-intro-btn" @click="exportKpis"><i
                        class="fas fa-upload export-print-kpis"></i></div>
                </div>
                <div v-if="datas_exists" class="header-kpi-intro-bnts">
                    <div v-if="Object.keys(this.irisMap).length > 1" class="kpi-intro-title-map">
                        <button class="kpi-intro-bnts-back" @click="refreshMap"><i class="fas fa-arrow-left"></i>
                        </button>
                        {{ title }}
                    </div>
                    <button class="kpi-intro-bnts" v-for="kpi in kpis.kpis" :key="'btn-ancrage-'+kpi"
                            :id="'btn-ancrage-'+kpi.title" @click="callView(kpi.title)">{{ kpi.title }}
                    </button>
                </div>
                <div v-if="Object.keys(this.irisMap).length <= 1 && datas_exists" class="headers-kpi-PAR-div">
                    <div class="headers-kpi-PAR-text">
                        <h4 class="headers-kpi-PAR-title">POINTS A RETENIR</h4>
                        <div class="headers-kpi-PAR-detail" v-html="points"></div>
                    </div>
                </div>
                <div v-if="datas_exists" class="kpi-content-div">
                    <!--          <KpiComponentCO v-for = "kpi in kpis.kpis" :key = "kpi"-->
                    <!--                          :id = "'kpi-component-'+kpi.title"-->
                    <!--                          backgroundColor = "#fefefe"-->
                    <!--                          color = "#555555"-->
                    <!--                          :open = "kpi.display"-->
                    <!--                          :title = "kpi.title"-->
                    <!--                          :kpis = "kpi.details"-->
                    <!--                          :limit = "limit"-->
                    <!--                          :gridCS = "kpi.gridCS"-->
                    <!--                          :gridCE = "kpi.gridCE"-->
                    <!--                          :gridRS = "kpi.gridRS"-->
                    <!--                          :gridRE = "kpi.gridRE"-->
                    <!--                          :gridSize= "kpi.size"-->
                    <!--                          @callList = "closeAndcallList"-->
                    <!--                          @syncKpis = "syncKpis"-->
                    <!--                          @callMap = "callMap"-->
                    <!--                          :datasMap = "datasMap"-->
                    <!--                          :year = "year"-->
                    <!--                          :map_filter = "map_filter"-->
                    <!--                          :entity = "entity"-->
                    <!--          />-->
                    <div class="page-export export-elem">
                        <div v-if="Object.keys(this.irisMap).length <= 1 && datas_exists"
                             class="headers-kpi-PAR-div-export">
                            <div id="title-log-touriz-export">
                                <img id="logo-touriz-img-export" src="logo_touriz.png" alt="logo touriz">
                                <h4 id="title-touriz-export">L'Observatoire des locations meublées touristiques<br>
                                    <div id="subtitle-touriz-nav2">(données ELAN {{ year }})</div>
                                </h4>
                            </div>
                            <div id="subtitle-log-touriz-export">
                                <h6 id="subtitle-touriz-export">Exporté le {{ getDate() }} par {{user.firstName +' '+ user.lastName}}  </h6>
                            </div>
                            <div class="headers-kpi-PAR-text-export">
                                <h4 class="headers-kpi-PAR-title">POINTS A RETENIR</h4>
                                <div class="headers-kpi-PAR-detail" v-html="points"></div>
                            </div>
                        </div>
                        <KpiComponentCO v-for="kpi in kpis_export_1" :key="kpi"
                                        :id="'kpi-component-'+kpi.title"
                                        backgroundColor="#fefefe"
                                        color="#555555"
                                        :open="kpi.display"
                                        :title="kpi.title"
                                        :kpis="kpi.details"
                                        :limit="limit"
                                        :gridCS="kpi.gridCS"
                                        :gridCE="kpi.gridCE"
                                        :gridRS="kpi.gridRS"
                                        :gridRE="kpi.gridRE"
                                        :gridSize="kpi.size"
                                        @callList="closeAndcallList"
                                        @syncKpis="syncKpis"
                                        @callMap="callMap"
                                        :datasMap="datasMap"
                                        :year="year"
                                        :map_filter="map_filter"
                                        :entity="entity"
                        />
                    </div>
                    <div class="page-export export-elem">
                        <KpiComponentCO v-for="kpi in kpis_export_2" :key="kpi"
                                        :id="'kpi-component-'+kpi.title"
                                        backgroundColor="#fefefe"
                                        color="#555555"
                                        :open="kpi.display"
                                        :title="kpi.title"
                                        :kpis="kpi.details"
                                        :limit="limit"
                                        :gridCS="kpi.gridCS"
                                        :gridCE="kpi.gridCE"
                                        :gridRS="kpi.gridRS"
                                        :gridRE="kpi.gridRE"
                                        :gridSize="kpi.size"
                                        @callList="closeAndcallList"
                                        @syncKpis="syncKpis"
                                        @callMap="callMap"
                                        :datasMap="datasMap"
                                        :year="year"
                                        :map_filter="map_filter"
                                        :entity="entity"
                        />
                    </div>
                </div>
                <div v-else class="datas_no_exist">
                    <p>Aucune donnée n'a été enregistée sur l'année {{ this.year }} pour la commune de
                        {{ entity_select.label.charAt(0).toUpperCase() + entity_select.label.slice(1) }}</p>
                    <p>Merci de contacter le support pour plus d'informations</p>
                </div>
            </div>
        </div>
    </div>
    <div v-else-if="entity !== '' && x_token !== undefined && x_token !== '' && dashboard === 'chart'"
         class="base-grid">
        <div class="base-grid-menu">
            <NavBar
                :env="env"
                :user="user"
                :dashboard="dashboard"
                :logo="logo"
                :entities="entities_user"
                :options="searchOptions"
                :clickKpi="clickKpi"
                :year="year"
                :limit="limit"
                :entity="entity"
                :entity_select="entity_select"
                :specif="specif"
                :map_filter="map_filter"
                @callSearch="callSearch"
                @callYear="callYear"
                @callResult="callResult"
                @callChart="callChart"
                @callEntity="callEntity"
                @callMapFilter="callMapFilter"
                @resetListUser="resetListUser"
                @callAdminBoard="callAdminBoard"

            />
        </div>
        <div class="base-grid-chart">
            <ListAnnonces v-if="Object.keys(list).length > 0"
                          :list=list
                          type='chart'
                          gridCS="1"
                          gridCE="21"
                          gridRS="1"
                          gridRE="21"
                          :limit="limit"
                          :specif="specif"
                          :cluster="cluster"
                          :id_cluster="id_cluster"
                          :id_annonce="annonceId"
                          @callList="callList"
                          @chargeList="chargeList"
                          @closeList="closeList"
                          @callAnnonce="callAnnonce"
                          @callAnnonceCluster="callAnnonceCluster"
                          @callAnnoncePlateforme="callAnnoncePlateforme"
                          @callMenuChart="callMenuChart"
                          @callConformites="callConformites"
                          @resetListUser="resetListUser"
                          @callMap="callMap"
                          @openMap="openMapKpi"
                          @openVideo="openVideo"
                          :user_list="user_list"
                          :entity_list="entity_list"
                          :entity="entity"
                          :year="year"
                          :glossaire="glossaire"
                          :ids="ids"
                          :user="user"
            />
            <div v-else class="loader-affichage-list">
                <LoaderParams/>
            </div>
        </div>
    </div>
    <div v-else-if="dashboard === 'adminBoard'" class="base-grid">
        <div class="base-grid-menu">
            <NavBar
                :env="env"
                :user="user"
                :dashboard="dashboard"
                :logo="logo"
                :entities="entities_user"
                :options="searchOptions"
                :clickKpi="clickKpi"
                :year="year"
                :limit="limit"
                :entity="entity"
                :entity_select="entity_select"
                :specif="specif"
                :map_filter="map_filter"
                @callSearch="callSearch"
                @callYear="callYear"
                @callResult="callResult"
                @callChart="callChart"
                @callEntity="callEntity"
                @callMapFilter="callMapFilter"
                @resetListUser="resetListUser"
                @callAdminBoard="callAdminBoard"

            />
        </div>
        <div class="base-grid-admin">
            <AdminBoard
                :entities="entities_user"
                :env="env"
                :user="user"
                :year="year"
                :map_filter="map_filter"
            />
        </div>
    </div>
    <div v-else class="base-grid-connect">
        <ConnectFormNewUser v-if="token_new_user" :token="token_new_user" :env="env"/>
        <ConnectFormModifPsw v-else-if="recover" :token="recover" :env="env"/>
        <ConnectFormConfimMail v-else-if="token" :token="token" :env="env"/>
        <ConnectFormRecover v-else-if="recover_mdp" :token="recover_mdp" :env="env"/>
        <ConnectForm v-else :entities_user="entities_user" @callUserEntity="callUserEntity" :env="env"/>
    </div>
</template>

<script>
import olMap from "./olMap";
import olMapPop from "./olMapPop";
import olFunction from "./olFunction";
import KpiComponent from "@/components/KpiComponent";
import volt from "./volt";
import MapsVue from "@/components/MapsVue";
import NavBar from "@/elements/NavBar";
import ListAnnonces from "@/elements/ListAnnonces";
import list from "./services/listes";
import PopAnnonce from "@/elements/PopAnnonce";
import PageLoader from "@/components/PageLoader";
import PopMenuChart from "@/elements/PopMenuChart";
import LoaderParams from "@/components/LoaderParams";
import PopConformites from "@/elements/PopConformites";
import KpiComponentCO from "@/components/KpiComponentCO";
import ConnectForm from "@/elements/ConnectForm";
import html2canvas from "@/services/html2canvas";
import ConnectFormNewUser from "@/elements/ConnectFormNewUser";
import ConnectFormConfimMail from "@/elements/ConnectFormConfimMail";
import ConnectFormModifPsw from "@/elements/ConnectFormModifPsw";
import ConnectFormRecover from "@/elements/ConnectFormRecover";
import AdminBoard from "@/elements/adminBoard";
import PageError from "@/components/PageError";
import VideoPlayer from "@/components/VideoPlayer.vue";

export default {
    name: 'App',
    props: {
        entity_connect: String,
        id_token: String,
        token_new_user: String,
        token: String,
        recover: String,
        recover_mdp: String
    },
    async mounted() {
        volt.getEnv().then((env) => {
            if (env.logo[this.entity] === undefined) {
                this.logo = env.logo['all'];
            } else {
                this.logo = env.logo[this.entity];
            }
            this.year = env.year;
            this.limit = env.limit;
            this.map_filter = env.map_filter;
            this.env = env;
            this.searchOptions = env.searchs;
            this.dashboard = env.dashboard;
            setTimeout(() => {
                olMap.callMap('ol');
            }, 500);
            if (sessionStorage.getItem('x-entity') && sessionStorage.getItem('x-entity') !== '') {
                this.entity = sessionStorage.getItem('x-entity');
            }
            if (sessionStorage.getItem('x-token') && sessionStorage.getItem('x-token') !== '') {
                this.x_token = sessionStorage.getItem('x-token');
                sessionStorage.setItem('token', sessionStorage.getItem('x-token'));
            }
        }).catch((reason) => {
            this.openWindowsError({error_code: 70101, error_msg: reason});
        });
        volt.getGlossaire().then((glos) => {
            this.glossaire = glos.variables_api;
        }).catch((reason) => {
            this.openWindowsError({error_code: 70102, error_msg: reason});
        });
    },
    components: {
        VideoPlayer,
        PageError,
        AdminBoard,
        ConnectFormRecover,
        ConnectFormModifPsw,
        ConnectFormConfimMail,
        ConnectFormNewUser,
        ConnectForm,
        KpiComponentCO,
        PopConformites,
        LoaderParams,
        PopMenuChart,
        PageLoader,
        PopAnnonce,
        ListAnnonces,
        NavBar,
        MapsVue,
        KpiComponent
    },
    methods: {
        getDate(){
          return volt.today_fr();
        },
        closeVideo() {
            this.videoView = false;
        },
        openVideo() {
            this.videoView = true;
        },
        closeWindowsError() {
            this.error_page = false;
            this.error_reason = {};
        },
        openWindowsError(error) {
            this.error_page = true;
            this.error_reason = error;
            volt.tak('page-loader').style.display = '';
            console.log(error.error_msg);
        },
        callAdminBoard() {
            this.dashboard = 'adminBoard';
        },
        async exportKpis() {
            volt.taks('.headers-kpi-PAR-div-export', '', false).forEach((el) => {
                el.style.display = 'grid';
            });
            volt.taks('.headers-kpi-PAR-div', '', false).forEach((el) => {
                el.style.display = 'none';
            });
            await html2canvas.generatePdf(this.logo);
            volt.taks('.headers-kpi-PAR-div-export', '', false).forEach((el) => {
                el.style.display = '';
            });
            volt.taks('.headers-kpi-PAR-div', '', false).forEach((el) => {
                el.style.display = '';
            });
        },
        resetListUser() {
            if (this.entity !== undefined && this.entity !== '') {
                volt.get('obs/list/find?year=' + this.year + '&type=user', this.entity).then((list_user) => {
                    this.user_list = list_user;
                }).catch((reason) => {
                    this.openWindowsError({error_code: 70001, error_msg: reason});
                })
                volt.get('obs/list/find?year=' + this.year + '&type=entity', this.entity).then((entity_list) => {
                    this.entity_list = entity_list;
                }).catch((reason) => {
                    this.openWindowsError({error_code: 70002, error_msg: reason});
                })
            }
        },
        callMapFilter() {
            this.map_filter = volt.map_filter;
        },
        callView(title) {
            volt.taks('.kpi-intro-bnts', '', false).forEach((el) => {
                el.style.outline = "";
            });
            if (title !== '') {
                volt.tak('btn-ancrage-' + title).style.outline = "solid 2px #f3b61bcc";
                if (title.split('-')[0] === '1 ') {
                    volt.tak('kpi-all-top-coupdoeil').scrollIntoView({behavior: "smooth"});
                } else {
                    volt.tak('ancre-kpi-details-' + title).scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                        inline: "end"
                    });
                }
                setTimeout(() => {
                    volt.tak('btn-ancrage-' + title).style.outline = "solid 2px #f3b61bcc";
                }, 800)
            }
        },
        callConformites() {
            this.callMenu('Mise à jour');
        },
        callSearch(data) {
            if (Object.keys(this.list).length > 0) {
                volt.data_like = data;
                this.data_like = data;
                setTimeout(() => {
                    this.closeAndcallList()
                }, 200);
            } else {
                this.refreshMap();
                volt.get('obs/elan/find?q=annee&facet=' + this.year, this.entity).then((annonces) => {
                    volt.list = {
                        name: 'totalAnnonces',
                        value: '',
                        size: annonces.length,
                        type: '',
                        limit: this.limit,
                        offset: 0,
                        order: 1,
                        by: ''
                    };
                    volt.data_like = data;
                    this.data_like = data;
                    this.clickKpi = 1;
                }).catch((reason) => {
                    this.openWindowsError({error_code: 70003, error_msg: reason});
                });
            }
        },
        refreshMap() {
            this.irisMap = {};
            volt.tak('page-loader').style.display = 'flex';
            volt.feature = {};
            this.feature = {};
            this.specif = "elan";
            if (this.entity_select.type === "2") {
                this.specif = "ept";
                volt.map_filter = 3;
                this.map_filter = 3;
            }
            list.getComKpis(this.entity, this.year, this.specif).then((k) => {
                this.feature = {};
                this.kpis = k;
                if (this.kpis.kpis[2].details[0].value > 0) {
                    this.datas_exists = true;
                } else {
                    this.datas_exists = false;
                }
                if (this.map) this.map.removeLayer(this.layer);
                if (this.map) this.map.removeLayer(this.layerAnnoncesConf);
                if (this.map) this.map.removeLayer(this.layerAnnoncesNconf);
                if (this.map) this.map.removeLayer(this.layerAnnoncesAVerif);
                if (this.datasMap?.arrondissements?.features && this.datasMap.arrondissements.features.length > 0) {
                    this.layer = olFunction.callLayerTourizOlPolygone(volt.o(this.datasMap.arrondissements), true);
                } else if (this.datasMap?.iris?.features && this.datasMap.iris.features.length > 0) {
                    this.layer = olFunction.callLayerTourizOlPolygone(volt.o(this.datasMap.iris), true);
                } else if (this.datasMap?.entities?.features && this.datasMap.entities.features.length > 0) {
                    this.layer = olFunction.callLayerTourizOlPolygone(volt.o(this.datasMap.entities), true);
                } else {
                    this.layer = olFunction.callLayerTourizOlPolygone(volt.o(this.datasMap.iris), true);
                }
                if (this.map) this.map.addLayer(this.layer);
                if (this.datasMap.center) olFunction.mapSetViewFeature(this.map, 13, volt.o(this.datasMap.center));
                volt.tak('page-loader').style.display = '';
                this.closeList(true);
            }).catch((reason) => {
                this.openWindowsError({error_code: 70004, error_msg: reason});
                // this.datas_exists = false;
            });
        },
        openMapKpi() {
            this.clickKpi++;
        },
        openMapEntity(load) {
            if (this.entity !== undefined && this.entity !== '') {
                volt.get('entity/find?label=' + this.entity).then((ent) => {
                    this.entity_select = ent;
                    this.city_code = this.entity_select.city_code;
                    this.entity = this.entity_select.label;
                    // this.logo = this.env.logo[this.entity];
                    if (this.env.logo[this.entity] === undefined) {
                        this.logo = this.env.logo['all'];
                    } else {
                        this.logo = this.env.logo[this.entity];
                    }
                    // this.year = this.env.year;
                    if (ent.type === "1" || ent.type === undefined) {
                        list.dispachIris(this.entity_select, this.year, load).then((datas) => {
                            this.datasMap = datas;
                            volt.tak('page-loader').style.display = 'none';
                            if (volt.tak('map-ol')) volt.tak('map-ol').innerHTML = '';
                            if (this.datasMap.center) this.map = olMap.callMap('ol', volt.o(this.datasMap.center), 13);
                            this.refreshMap();
                        }).catch((reason) => {
                            this.openWindowsError({error_code: 70005, error_msg: reason});
                            if (this.datasMap.center) this.map = olMap.callMap('ol', volt.o(this.datasMap.center), 13);
                        });
                    } else {
                        list.dispachIris(this.entity_select, this.year, load).then((datas) => {
                            this.datasMap = datas;
                            this.specif = 'ept';
                            volt.tak('page-loader').style.display = 'none';
                            if (volt.tak('map-ol')) volt.tak('map-ol').innerHTML = '';
                            this.map = olMap.callMap('ol', volt.o(this.datasMap.center), 13);
                            this.refreshMap();
                        }).catch((reason) => {
                            this.openWindowsError({error_code: 70006, error_msg: reason});
                        });
                    }
                }).catch((reason) => {
                    this.openWindowsError({error_code: 70007, error_msg: reason});
                })
            }
        },
        callMap() {
            setTimeout(() => {
                if (Object.keys(volt.feature).length > 0 && Object.keys(this.irisMap).length <= 1) {
                    this.kpis = {};
                    volt.tak('page-loader').style.display = 'flex';
                    this.feature = volt.feature;
                    this.specif = 'iris';
                    let specif_value = '';
                    let quartier_name = '';
                    if (volt.feature.properties?.arrondissement_rapport === 1) {
                        this.specif = 'arrondissement';
                        specif_value = volt.feature.properties.code_insee;
                        quartier_name = volt.feature.properties.arrondissement.toUpperCase() + '';
                    } else if (volt.feature.properties?.ept_rapport === 1) {
                        this.specif = '';
                        this.entity = volt.feature.properties.entity.entity;
                        this.map_filter = volt.map_filter = 1;
                    } else {
                        specif_value = volt.feature.properties.iris_code;
                        quartier_name = 'QUARTIER IRIS ' + volt.feature.properties.iris_name.toUpperCase() + '';
                    }
                    list.getComKpis(this.entity, this.year, this.specif, specif_value).then((k) => {
                        this.kpis = k;
                        this.title = quartier_name;
                        this.map.removeLayer(this.layer);
                        if (this.specif === 'iris') {
                            list.detailIris(volt.feature, this.entity, this.year).then((irisMap) => {
                                this.irisMap = irisMap;
                                if (this.irisMap.arrondissements !== undefined && this.irisMap.arrondissements.length > 0) {
                                    this.layer = olFunction.callLayerTourizOlPolygone(volt.o(this.irisMap.iris), true, 'inactif');
                                    this.map.addLayer(this.layer);
                                }
                                this.callLayers(this.irisMap);
                                olFunction.mapSetViewFeature(this.map, 14, volt.o(this.irisMap.center));
                                volt.tak('page-loader').style.display = '';
                            }).catch((reason) => {
                                this.openWindowsError({error_code: 70103, error_msg: reason});
                            });
                        } else if (this.specif === 'arrondissement') {
                            list.detailArrondissement(volt.feature, this.entity, this.year).then((irisMap) => {
                                this.irisMap = irisMap;
                                if (this.irisMap.arrondissements !== undefined && this.irisMap.arrondissements.length > 0) {
                                    this.layer = olFunction.callLayerTourizOlPolygone(volt.o(this.irisMap.arrondissements), true, 'inactif');
                                    this.map.addLayer(this.layer);
                                }
                                this.callLayers(this.irisMap);
                                olFunction.mapSetViewFeature(this.map, 14, volt.o(this.irisMap.center));
                                volt.tak('page-loader').style.display = '';
                            }).catch((reason) => {
                                this.openWindowsError({error_code: 70104, error_msg: reason});
                            });
                        } else if (this.specif === 'ept') {
                            list.detailEntity(volt.feature, this.entity, this.year).then((entitiesMap) => {
                                this.irisMap = entitiesMap;
                                if (this.irisMap.entities !== undefined && this.irisMap.entities.length > 0) {
                                    this.layer = olFunction.callLayerTourizOlPolygone(volt.o(this.irisMap.entities), true, 'inactif');
                                    this.map.addLayer(this.layer);
                                }
                                this.callLayers(this.irisMap);
                                olFunction.mapSetViewFeature(this.map, 14, volt.o(this.irisMap.center));
                                volt.tak('page-loader').style.display = '';
                            }).catch((reason) => {
                                this.openWindowsError({error_code: 70105, error_msg: reason});
                            });
                        }
                    }).catch((reason) => {
                        this.openWindowsError({error_code: 70008, error_msg: reason});
                    });
                } else {
                    if (volt.annonce !== '') {
                        this.callAnnonce('meublés');
                    }
                }
            }, 500);
        },
        callYear() {
            this.year = volt.annee
        },
        callResult() {
            this.limit = parseInt(volt.nbr_result);
            volt.post('users/profil/update/result_nbr', {result_nbr: parseInt(volt.nbr_result)}).then((res) => {
                console.log(res);
            }).catch((reason) => {
                this.openWindowsError({error_code: 70009, error_msg: reason});
            });
        },
        callChart() {
            this.dashboard = volt.tableau;
        },
        callEntity() {
            this.entity = volt.commune
        },
        syncKpis() {
            this.clickSync++;
        },
        callList() {
            this.clickKpi++;
        },
        closeAndcallList() {
            this.offset = 0;
            this.size = 0;
            this.clickKpi++;
        },
        chargeList(list_) {
            volt.tak('page-loader').style.display = 'flex';
            this.size = 0;
            this.clickKpi = 0;
            this.closeList(false);
            let name = 'user';
            let q = '';
            let facet = '';
            if (list_ !== undefined) {
                if (list_.name !== undefined && list_.name !== '') name = list_.name;
                if (list_.q !== undefined && list_.q !== '') q = list_.q;
                if (list_.facet !== undefined && list_.facet !== '') facet = list_.facet;
                if (list_._id !== undefined && list_._id !== '') this.list_id = list_._id;
            }
            volt.list = {
                name: name,
                value: '',
                size: 0,
                type: '',
                limit: this.limit,
                offset: 0,
                order: 1,
                by: ''
            };
            let order = 1;
            let by = '';
            if (Object.keys(volt.sort_vals).length > 0) {
                for (let key in volt.sort_vals) {
                    order = volt.sort_vals[key].order;
                    if (volt.sort_vals[key].prefield !== '' && volt.sort_vals[key].prefield !== 'not') {
                        by = volt.sort_vals[key].prefield + '.' + volt.sort_vals[key].field;
                    } else {
                        by = volt.sort_vals[key].field;
                    }
                    // by = volt.sort_vals[key].prefield+'.'+volt.sort_vals[key].field;
                }
            }
            this.resetListUser();
            list.call(volt.list.name, this.year, this.size, volt.list.offset, this.limit, order, by, this.entity, {}, q, facet).then((l) => {
                volt.list = l;
                this.list = l;
                this.ids = l.ids;
                this.annonces = l.features;
                volt.tak('page-loader').style.display = '';
                // this.resetListUser();
            }).catch((reason) => {
                console.log(reason);
                volt.tak('page-loader').style.display = '';
            });
        },
        callListPop() {
            volt.tak('list-loader-popup').style.display = 'flex';
            list.call(volt.listPop.name, this.year, volt.listPop.size, volt.listPop.offset, this.limit, volt.listPop.order, volt.listPop.by, this.entity, this.annonce).then((l) => {
                volt.listPop = l;
                this.listPop = l;
                if (volt.tak('list-loader-popup')) {
                    volt.tak('list-loader-popup').style.display = '';
                }
            }).catch((reason) => {
                console.log(reason);
                volt.tak('page-loader').style.display = '';
            });
        },
        closeList(update = false) {
            volt.data_like = {};
            this.data_like = {};
            this.clickKpi = 0;
            volt.list = {};
            this.list = {};
            this.size = 0;
            this.searchOptions = this.env.searchs;
            if (update) {
                setTimeout(() => {
                    let title = "5 - ZOOM LEGALITE D'HEBERGEMENTS TOURISTIQUES";
                    if (volt.tak("kpi-loader-" + title)) {
                        volt.tak("kpi-loader-" + title).style.display = "flex";
                        volt.sync = title;
                        this.clickSync++;
                    }
                }, 200);
            }
        },
        callUserEntity() {
            if (volt.tak('select-entity-connect') === undefined || volt.tak('select-entity-connect') === null) {
                const data = {
                    login: volt.tak('input-login-connect').value,
                    password: volt.tak('input-password-connect').value
                }
                volt.post('users/authenticate', data).then((user) => {
                    volt.tak('alert-connect').style.display = "";
                    volt.tak('alert-connect').innerText = "";
                    sessionStorage.setItem('token', user.token);
                    this.x_token = user.token;
                    this.user = user;
                    if (user.entities.length > 1) {
                        this.entities_user = user.entities;
                    } else {
                        this.entity = user.entities[0].entity;
                        // this.logo = this.env.logo[this.entity];
                        if (this.env.logo[this.entity] === undefined) {
                            this.logo = this.env.logo['all'];
                        } else {
                            this.logo = this.env.logo[this.entity];
                        }
                        this.year = this.env.year;
                        this.limit = user.limit;
                        volt.get('entity/find?label=' + this.entity).then((ent) => {
                            this.entity_select = ent;
                            this.city_code = this.entity_select.city_code;
                            this.entity = this.entity_select.label;
                            // this.logo = this.env.logo[this.entity];
                            if (this.env.logo[this.entity] === undefined) {
                                this.logo = this.env.logo['all'];
                            } else {
                                this.logo = this.env.logo[this.entity];
                            }
                            this.year = this.env.year;
                            this.limit = user.limit;
                            this.resetListUser();
                        })
                    }
                }).catch((reason) => {
                    console.log(reason);
                    volt.tak('alert-connect').style.display = "block";
                    volt.tak('alert-connect').innerText = reason.error_msg.error_msg;
                })
            } else {
                this.entities_user.forEach((ent_use) => {
                    if (ent_use.entity === volt.tak('select-entity-connect').value) {
                        volt.get('entity/find?label=' + ent_use.entity).then((ent) => {
                            this.entity_select = ent;
                            this.city_code = this.entity_select.city_code;
                            this.entity = this.entity_select.label;
                            if (this.env.logo[this.entity] === undefined) {
                                this.logo = this.env.logo['all'];
                            } else {
                                this.logo = this.env.logo[this.entity];
                            }
                            // this.logo = this.env.logo[this.entity];
                            this.year = this.env.year;
                            this.limit = this.user.limit;
                            this.resetListUser();
                        }).catch((reason) => {
                            this.openWindowsError({error_code: 70301, error_msg: reason});
                        });
                    }
                })
            }
        },
        callMenuChart(list_id) {
            this.list_id = list_id;
            this.callMenu('Paramètres de la liste');
        },
        callAnnonceCluster(cl) {
            this.cluster = cl['type_cluster'];
            this.id_cluster = cl['cluster'];
            let id_annonce = cl['annonce_id'];
            this.clickAnnonce++;
            this.annonceId = volt.annonce;
            volt.tak('map-pop').innerHTML = "";
            volt.maps['pop'] = '';
            this.callMenu('Annonce');
            volt.listPop = {
                name: cl['type_cluster'],
                value: '',
                size: 0,
                type: '',
                limit: 2,
                offset: 0,
                order: 1,
                by: ''
            };
            let selVal = volt.select_vals;
            volt.select_vals = {};
            list.call(volt.listPop.name, this.year, volt.listPop.size, volt.listPop.offset, this.limit, volt.listPop.order, volt.listPop.by, this.entity, this.id_cluster).then((l) => {
                if (id_annonce !== undefined) {
                    l.features.forEach((f) => {
                        if (f._id === id_annonce) {
                            this.annonce = f;
                        }
                    });
                } else {
                    this.annonce = l.features[0];
                    volt.annonce = this.annonce._id;
                    this.annonceId = volt.annonce;
                }
                volt.listPop = l;
                this.listPop = l;
                if (Object.keys(this.mapPop).length > 0) {
                    this.mapPop.removeLayer(this.layerAnnoncePop);
                }
                this.mapPop = olMapPop.callMap('pop', volt.o(this.datasMap.center), 13);
                if (this.annonce !== undefined) {
                    this.layerAnnoncePop = olFunction.callLayer(volt.o(this.annonce.feature), 'true', 'icone', 'conforme', 100);
                    this.mapPop.addLayer(this.layerAnnoncePop);
                }
                volt.select_vals = selVal;
            }).catch((reason) => {
                console.log(reason);
                volt.tak('page-loader').style.display = '';
            });
        },
        callAnnonce(cluster) {
            this.id_cluster = '';
            this.clickAnnonce++;
            this.annonce = {};
            this.annonceId = volt.annonce;
            volt.tak('map-pop').innerHTML = "";
            setTimeout(() => {
                volt.tak('kpi-loader-pop-annonce').style.display = "block";
            }, 10);
            volt.maps['pop'] = '';
            this.callMenu('Annonce');
            volt.get('obs/elan_id/find?q=' + this.annonceId, this.entity).then((result) => {
                this.annonce = result[0];
                // volt.tak('kpi-loader-pop-annonce').style.display = "none";
                if (this.year > 2021) {
                    if (cluster === 'meublés') {
                        volt.get('obs/elan_cluster_map/find?cluster=' + this.annonce.feature.properties.cluster_adresse_multi_meubles + '&year=' + this.year, this.entity).then((nbr) => {
                            volt.listPop = {
                                name: this.env.popup_list_meuble,
                                value: '',
                                size: nbr.annonces,
                                type: '',
                                limit: 2,
                                offset: 0,
                                order: 1,
                                by: ''
                            };
                            list.call(volt.listPop.name, this.year, volt.listPop.size, volt.listPop.offset, this.limit, volt.listPop.order, volt.listPop.by, this.entity, this.annonce).then((l) => {
                                volt.listPop = l;
                                this.listPop = l;
                            }).catch((reason) => {
                                console.log(reason);
                                volt.tak('page-loader').style.display = '';
                            });
                        }).catch((reason) => {
                            console.log(reason);
                            volt.tak('page-loader').style.display = '';
                        });
                    } else {
                        volt.get('obs/elan_cluster/find?cluster=' + this.annonce.feature.properties.cluster_multiloueur + '&year=' + this.year, this.entity).then((nbr) => {
                            volt.listPop = {
                                name: this.env.popup_list,
                                value: '',
                                size: nbr.annonces,
                                type: '',
                                limit: 2,
                                offset: 0,
                                order: 1,
                                by: ''
                            };
                            list.call(volt.listPop.name, this.year, volt.listPop.size, volt.listPop.offset, this.limit, volt.listPop.order, volt.listPop.by, this.entity, this.annonce).then((l) => {
                                volt.listPop = l;
                                this.listPop = l;
                            }).catch((reason) => {
                                console.log(reason);
                                volt.tak('page-loader').style.display = '';
                            });
                        }).catch((reason) => {
                            console.log(reason);
                            volt.tak('page-loader').style.display = '';
                        });
                    }
                }
                setTimeout(() => {
                    volt.tak('map-pop').innerHTML = "";
                    volt.maps['pop'] = '';
                    if (Object.keys(this.mapPop).length > 0) {
                        this.mapPop.removeLayer(this.layerAnnoncePop);
                    }
                    this.mapPop = olMapPop.callMap('pop', volt.o(this.datasMap.center), 13);
                    this.layerAnnoncePop = olFunction.callLayer(volt.o(this.annonce.feature), 'true', 'icone', 'google-map', 100);
                    this.mapPop.addLayer(this.layerAnnoncePop);
                }, 400);
            }).catch((reason) => {
                this.openWindowsError({error_code: 70108, error_msg: reason});
            });
        },
        callAnnoncePlateforme(plateforme) {
            volt.tak('page-loader').style.display = 'flex';
            this.size = 0;
            this.clickKpi = 0;
            this.closeList(false);
            let name = 'user';
            let q = '';
            let facet = '';
            volt.select_vals = {};
            volt.select_vals['nom_plateforme'] = {
                field: 'nom_plateforme',
                prefield: "feature.properties",
                values: [plateforme]
            }
            volt.list = {
                name: name,
                value: '',
                size: 0,
                type: '',
                limit: this.limit,
                offset: 0,
                order: 1,
                by: ''
            };
            let order = 1;
            let by = '';
            if (Object.keys(volt.sort_vals).length > 0) {
                for (let key in volt.sort_vals) {
                    order = volt.sort_vals[key].order;
                    if (volt.sort_vals[key].prefield !== '' && volt.sort_vals[key].prefield !== 'not') {
                        by = volt.sort_vals[key].prefield + '.' + volt.sort_vals[key].field;
                    } else {
                        by = volt.sort_vals[key].field;
                    }
                    // by = volt.sort_vals[key].prefield+'.'+volt.sort_vals[key].field;
                }
            }
            this.resetListUser();
            list.call(volt.list.name, this.year, this.size, volt.list.offset, this.limit, order, by, this.entity, {}, q, facet).then((l) => {
                volt.list = l;
                this.list = l;
                this.ids = l.ids;
                this.annonces = l.features;
                volt.tak('page-loader').style.display = '';
                // this.resetListUser();
            }).catch((reason) => {
                console.log(reason);
                volt.tak('page-loader').style.display = '';
            });
        },
        callMenu(e) {
            let el = volt.tak('menu-float-' + e);
            el.style.transition = "0.5s";
            if (el.style.transform === "scale(1)") {
                el.style.backgroundColor = "";
                setTimeout(() => {
                    el.style.backgroundColor = "";
                    setTimeout(() => {
                        el.style.backgroundColor = "";
                        setTimeout(() => {
                            el.style.backgroundColor = "";
                        }, 200);
                    }, 200);
                }, 200);
            }
            el.style.transform = "scale(1)";
            setTimeout(() => {
                el.style.transition = "";
            }, 700);
        },
        callLayers(coll) {
            this.map.removeLayer(this.layerAnnoncesConf);
            this.map.removeLayer(this.layerAnnoncesNconf);
            this.map.removeLayer(this.layerAnnoncesAVerif);
            this.layerAnnoncesConf = olFunction.callLayer(volt.o(coll.layers['conforme']), 'true', 'icone', 'conforme', 100);
            this.layerAnnoncesNconf = olFunction.callLayer(volt.o(coll.layers['non_conforme']), 'true', 'icone', 'non_conforme', 200);
            this.layerAnnoncesAVerif = olFunction.callLayer(volt.o(coll.layers['a_verifier']), 'true', 'icone', 'a_verifier', 300);
            this.map.addLayer(this.layerAnnoncesConf);
            this.map.addLayer(this.layerAnnoncesNconf);
            this.map.addLayer(this.layerAnnoncesAVerif);
        },
        refreshEntity(load) {
            volt.tak('page-loader').style.display = 'flex';
            if (volt.tak('map-ol')) volt.tak('map-ol').innerHTML = '';
            volt.maps = [];
            this.openMapEntity(load);
        },
        getEntetes(annonces) {
            for (let detail in annonces[0]) {
                if (annonces[0][detail] !== null && typeof annonces[0][detail] === "object") {
                    this.entetes[detail] = [];
                    Object.keys(annonces[0][detail]).forEach((enteteSub) => {
                        if (annonces[0][detail][enteteSub] !== null && typeof annonces[0][detail][enteteSub] === "object") {
                            this.entetes[detail][enteteSub] = [];
                            Object.keys(annonces[0][detail][enteteSub]).forEach((enteteSub1) => {
                                if (annonces[0][detail][enteteSub][enteteSub1] !== null && typeof annonces[0][detail][enteteSub][enteteSub1] === "object") {
                                    this.entetes[detail][enteteSub][enteteSub1] = [];
                                    Object.keys(annonces[0][detail][enteteSub][enteteSub1]).forEach((enteteSub2) => {
                                        if (annonces[0][detail][enteteSub][enteteSub1][enteteSub2] !== null && typeof annonces[0][detail][enteteSub][enteteSub1][enteteSub2] === "object") {
                                            this.entetes[detail][enteteSub][enteteSub1][enteteSub2] = [];
                                            Object.keys(annonces[0][detail][enteteSub][enteteSub1][enteteSub2]).forEach((enteteSub3) => {
                                                if (annonces[0][detail][enteteSub][enteteSub1][enteteSub2][enteteSub3] !== null && typeof annonces[0][detail][enteteSub][enteteSub1][enteteSub2][enteteSub3] === "object") {
                                                    this.entetes[detail][enteteSub][enteteSub1][enteteSub2][enteteSub3] = [];
                                                    Object.keys(annonces[0][detail][enteteSub][enteteSub1][enteteSub2][enteteSub3]).forEach((enteteSub4) => {
                                                        if (annonces[0][detail][enteteSub][enteteSub1][enteteSub2][enteteSub3][enteteSub4] !== null && typeof annonces[0][detail][enteteSub][enteteSub1][enteteSub2][enteteSub3][enteteSub4] === "object") {
                                                            this.entetes[detail][enteteSub][enteteSub1][enteteSub2][enteteSub3][enteteSub4] = [];
                                                        } else {
                                                            this.entetes[detail][enteteSub][enteteSub1][enteteSub2][enteteSub3].push(enteteSub4);
                                                        }
                                                    })
                                                } else {
                                                    this.entetes[detail][enteteSub][enteteSub1][enteteSub2].push(enteteSub3);
                                                }
                                            })
                                        } else {
                                            this.entetes[detail][enteteSub][enteteSub1].push(enteteSub2);
                                        }
                                    })
                                } else {
                                    this.entetes[detail][enteteSub].push(enteteSub1);
                                }
                            })
                        } else {
                            this.entetes[detail].push(enteteSub);
                        }
                    })
                } else {
                    this.entetes.push(detail);
                }
            }
            let tab_class = [];
            for (let key in this.glossaire) {
                if (tab_class[this.glossaire[key].class] === undefined) {
                    tab_class[this.glossaire[key].class] = [];
                    this.list_class.push(this.glossaire[key].class);
                }
            }
            this.entetes.forEach((entete) => {
                if (this.glossaire[entete]?.class !== undefined) {
                    tab_class[this.glossaire[entete]?.class].push(this.entetes[entete]);
                }
            });
            this.entetes.feature.properties.forEach((prop, i) => {
                if (this.glossaire[prop]?.class !== undefined) {
                    tab_class[this.glossaire[prop]?.class].push('feature.properties.' + this.entetes.feature.properties[i]);
                }
            })
            this.entetes.updates.forEach((prop, i) => {
                if (this.glossaire[prop]?.class !== undefined) {
                    tab_class[this.glossaire[prop]?.class].push('updates.' + this.entetes.updates[i]);
                }
            })
            this.entetes.adresse.forEach((prop, i) => {
                if (this.glossaire[prop]?.class !== undefined) {
                    tab_class[this.glossaire[prop]?.class].push('adresse.' + this.entetes.adresse[i]);
                }
            })
            let tab_concat = [];
            for (let key in tab_class) {
                tab_class[key].forEach((class_tab) => {
                    tab_concat = tab_concat.concat(class_tab);
                });
            }
            console.log(tab_concat);
            this.entetes = tab_concat;
        }
    },
    watch: {
        kpis() {
            this.kpis_export_1 = [];
            this.kpis_export_2 = [];
            if (this.kpis.kpis) {
                this.kpis.kpis.forEach((kpi, i) => {
                    if (i < 3) {
                        this.kpis_export_1.push(kpi);
                    } else {
                        this.kpis_export_2.push(kpi);
                    }
                });
            }
        },
        entity() {
            volt.get('entity/findInfo/?year=' + this.year + '&label=' + this.entity, this.entity).then((point) => {
                if (point.content !== undefined) {
                    this.points = point.content['VAR_VALEUR'];
                } else if (point.error_code !== undefined && point.error_code === 404) {
                    this.openWindowsError({error_code: 70020, error_msg: point});
                }
            }).catch((reason) => {
                this.openWindowsError({error_code: 70010, error_msg: reason});
            });
            this.dashboard = this.env.dashboard;
            this.refreshEntity();
            this.resetListUser();
        },
        year() {
            if (this.entity !== undefined && this.entity !== '') {
                volt.get('entity/findInfo/?year=' + this.year + '&label=' + this.entity, this.entity).then((point) => {
                    if (point.content !== undefined) {
                        this.points = point.content['VAR_VALEUR'];
                    } else if (point.error_code !== undefined && point.error_code === 404) {
                        this.openWindowsError({error_code: 70020, error_msg: point});
                    }
                }).catch((reason) => {
                    this.openWindowsError({error_code: 70011, error_msg: reason});
                });
                this.dashboard = this.env.dashboard;
                this.refreshEntity();
                this.resetListUser();
            }
        },
        clickKpi() {
            if (this.clickKpi > 0) {
                if (volt.tak('list-loader-observatoire')) {
                    volt.tak('list-loader-observatoire').style.display = 'flex';
                } else if (volt.tak('list-loader-chart')) {
                    volt.tak('list-loader-chart').style.display = 'flex';
                } else if (volt.tak('list-loader-coupdoeil')) {
                    volt.tak('list-loader-coupdoeil').style.display = 'flex';
                } else {
                    volt.tak('page-loader').style.display = 'flex';
                }
                let q = '';
                let facet = '';
                if (Object.keys(volt.feature).length > 0) {
                    if (volt.feature.properties?.arrondissement_rapport === 1) {
                        q = 'arrondissement';
                        facet = volt.feature.properties.code_insee;
                    } else if (volt.feature.properties?.ept_rapport === 1) {
                        console.log(volt.feature.properties);
                    } else {
                        q = 'iris'
                        facet = volt.feature.properties.iris_code;
                    }
                }
                if (this.offset === 0) {
                    volt.list.offset = 0;
                }
                list.call(volt.list.name, this.year, this.size, volt.list.offset, this.limit, volt.list.order, volt.list.by, this.entity, {}, q, facet).then((l) => {
                    console.log(volt.list.name);
                    if (volt.list.name === undefined || volt.list.name === 'user') this.getEntetes(l.features);
                    volt.list = l;
                    this.list = l;
                    this.list_id = l._id;
                    if (this.size === 0) {
                        this.ids = l.ids;
                    }
                    this.size = volt.list.size;
                    this.offset = 1;
                    this.annonces = l.features;
                    volt.tak('page-loader').style.display = '';
                    if (volt.tak('list-loader-observatoire')) {
                        volt.tak('list-loader-observatoire').style.display = '';
                    } else if (volt.tak('list-loader-chart')) {
                        volt.tak('list-loader-chart').style.display = '';
                    } else if (volt.tak('list-loader-coupdoeil')) {
                        volt.tak('list-loader-coupdoeil').style.display = '';
                    }
                    let featTab = [];
                    l.features.forEach((annonce) => {
                        annonce.feature.properties['_id'] = annonce['_id'];
                        featTab.push(annonce.feature);
                    })
                    if (this.dashboard === 'observatoire') {
                        if (this.clickKpi === 1) {
                            setTimeout(() => {
                                this.map = olMap.callMap('ol', volt.o(this.datasMap.center), 13);
                                if (Object.keys(volt.feature).length > 0) {
                                    this.layer = olFunction.callLayerTourizOlPolygone(volt.o(this.irisMap.iris), true, 'inactif');
                                    olFunction.mapSetViewFeature(this.map, 14, volt.o(this.irisMap.center));
                                } else {
                                    if (this.datasMap?.arrondissements?.features && this.datasMap.arrondissements.features.length > 0) {
                                        this.layer = olFunction.callLayerTourizOlPolygone(volt.o(this.datasMap.arrondissements), true, 'inactif');
                                    } else if (this.datasMap?.iris?.features && this.datasMap.iris.features.length > 0) {
                                        this.layer = olFunction.callLayerTourizOlPolygone(volt.o(this.datasMap.iris), true, 'inactif');
                                    } else if (this.datasMap?.entities?.features && this.datasMap.entities.features.length > 0) {
                                        this.layer = olFunction.callLayerTourizOlPolygone(volt.o(this.datasMap.entities), true, 'inactif');
                                    } else {
                                        this.layer = olFunction.callLayerTourizOlPolygone(volt.o(this.datasMap.iris), true, 'inactif');
                                    }
                                }
                                this.map.addLayer(this.layer);
                                this.callLayers(l);
                            }, 500);
                        } else {
                            setTimeout(() => {
                                this.callLayers(l);
                            }, 500);
                        }
                    } else if (this.dashboard === 'coupdoeil' || this.dashboard === 'chart') {
                        this.map = olMap.callMap('ol', volt.o(this.datasMap.center), 13);
                        if (Object.keys(volt.feature).length > 0) {
                            this.layer = olFunction.callLayerTourizOlPolygone(volt.o(this.irisMap.iris), true, 'inactif');
                            olFunction.mapSetViewFeature(this.map, 14, volt.o(this.irisMap.center));
                        } else {
                            if (this.datasMap?.arrondissements?.features && this.datasMap.arrondissements.features.length > 0) {
                                this.layer = olFunction.callLayerTourizOlPolygone(volt.o(this.datasMap.arrondissements), true, 'inactif');
                            } else if (this.datasMap?.iris?.features && this.datasMap.iris.features.length > 0) {
                                this.layer = olFunction.callLayerTourizOlPolygone(volt.o(this.datasMap.iris), true, 'inactif');
                            } else if (this.datasMap?.entities?.features && this.datasMap.entities.features.length > 0) {
                                this.layer = olFunction.callLayerTourizOlPolygone(volt.o(this.datasMap.entities), true, 'inactif');
                            } else {
                                this.layer = olFunction.callLayerTourizOlPolygone(volt.o(this.datasMap.iris), true, 'inactif');
                            }
                            // if(this.datasMap?.arrondissements?.features && this.datasMap.arrondissements.features.length > 0){
                            //   this.layer = olFunction.callLayerTourizOlPolygone(volt.o(this.datasMap.arrondissements), true, 'inactif');
                            // }else{
                            //   this.layer = olFunction.callLayerTourizOlPolygone(volt.o(this.datasMap.iris), true, 'inactif');
                            // }
                        }
                        this.map.addLayer(this.layer);
                        this.callLayers(l);
                    }
                }).catch((reason) => {
                    console.log(reason);
                    volt.tak('page-loader').style.display = '';
                });
            } else {
                if (this.dashboard !== 'coupdoeil') {
                    setTimeout(() => {
                        this.map = olMap.callMap('ol', volt.o(this.datasMap.center), 13);
                        if (Object.keys(volt.feature).length > 0) {
                            list.detailIris(volt.feature, this.entity, this.year).then((irisMap) => {
                                this.irisMap = irisMap;
                                this.layer = olFunction.callLayerTourizOlPolygone(volt.o(this.irisMap.iris), true, 'inactif');
                                this.map.addLayer(this.layer);
                                this.callLayers(this.irisMap);
                                olFunction.mapSetViewFeature(this.map, 14, volt.o(this.irisMap.center));
                            }).catch((reason) => {
                                this.openWindowsError({error_code: 70402, error_msg: reason});
                            });
                        } else {
                            if (this.datasMap?.arrondissements?.features && this.datasMap.arrondissements.features.length > 0) {
                                this.layer = olFunction.callLayerTourizOlPolygone(volt.o(this.datasMap.arrondissements), true);
                            } else if (this.datasMap?.iris?.features && this.datasMap.iris.features.length > 0) {
                                this.layer = olFunction.callLayerTourizOlPolygone(volt.o(this.datasMap.iris), true);
                            } else if (this.datasMap?.entities?.features && this.datasMap.entities.features.length > 0) {
                                this.layer = olFunction.callLayerTourizOlPolygone(volt.o(this.datasMap.entities), true);
                            } else {
                                this.layer = olFunction.callLayerTourizOlPolygone(volt.o(this.datasMap.iris), true);
                            }
                            // if(this.datasMap?.arrondissements?.features && this.datasMap.arrondissements.features.length > 0){
                            //   this.layer = olFunction.callLayerTourizOlPolygone(volt.o(this.datasMap.arrondissements), true);
                            // }else{
                            //   this.layer = olFunction.callLayerTourizOlPolygone(volt.o(this.datasMap.iris), true);
                            // }
                            this.map.addLayer(this.layer);
                        }
                    }, 300);
                }
            }
        },
        clickSync() {
            if (volt.sync !== '') {
                if (Object.keys(volt.feature).length > 0 && Object.keys(this.irisMap).length > 1) {
                    this.specif = 'iris';
                    let specif_value = '';
                    let quartier_name = '';
                    if (volt.feature.properties?.arrondissement_rapport === 1) {
                        this.specif = 'arrondissement';
                        specif_value = volt.feature.properties.code_insee;
                        quartier_name = volt.feature.properties.arrondissement.toUpperCase() + '';
                    } else if (volt.feature.properties?.ept_rapport === 1) {
                        this.specif = 'ept';
                        this.entity = volt.feature.properties.entity.entity;
                        this.map_filter = volt.map_filter = 1;
                    } else {
                        specif_value = volt.feature.properties.iris_code;
                        quartier_name = 'QUARTIER IRIS ' + volt.feature.properties.iris_name.toUpperCase() + '';
                    }
                    if (volt.tak('kpi-details-' + volt.sync)) volt.tak('kpi-details-' + volt.sync).innerHTML = "";
                    list.getComKpi(volt.sync, this.entity, this.year, this.specif, specif_value).then((k) => {
                        this.kpis = k;
                        this.kpis.title = this.kpis.title + quartier_name;
                        if (volt.sync === "4 - ZOOM URBANISME") {
                            this.refreshEntity('maj');
                        }
                        if (volt.tak('kpi-loader-' + volt.sync)) volt.tak('kpi-loader-' + volt.sync).style.display = "";
                        volt.sync = '';
                    }).catch((reason) => {
                        this.openWindowsError({error_code: 70407, error_msg: reason});
                    });
                } else {
                    if (this.entity_select.type === "2") {
                        this.specif = 'ept';
                    }
                    if (volt.tak('kpi-details-' + volt.sync)) volt.tak('kpi-details-' + volt.sync).innerHTML = "";
                    list.getComKpi(volt.sync, this.entity, this.year, this.specif).then((k) => {
                        this.kpis = k;
                        if (volt.sync === "4 - ZOOM URBANISME") {
                            this.refreshEntity('maj');
                        }
                        if (volt.tak('kpi-loader-' + volt.sync)) volt.tak('kpi-loader-' + volt.sync).style.display = "";
                        volt.sync = '';
                    }).catch((reason) => {
                        this.openWindowsError({error_code: 70408, error_msg: reason});
                    });
                }
            }
        },
        list() {
            if (this.clickKpi > 0) {
                this.searchOptions = [];
                this.list.headers.forEach((header) => {
                    if (header.search) {
                        this.searchOptions.push({label: header.name, link: header.field});
                    }
                });
            }
        },
        dashboard() {
            this.clickKpi = 0;
            this.closeList(true);
            volt.tak('page-loader').style.display = 'flex';
            if (volt.tak('map-ol')) {
                volt.tak('map-ol').innerHTML = '';
            }
            if (this.dashboard === 'observatoire') {
                setTimeout(() => {
                    if (volt.tak('map-ol').innerHTML === '') {
                        this.map = olMap.callMap('ol', volt.o(this.datasMap.center), 13);
                    }
                    this.refreshMap();
                }, 500);
                volt.tak('page-loader').style.display = '';
            } else if (this.dashboard === 'chart') {
                this.refreshMap();
                setTimeout(() => {
                    volt.list = {
                        name: this.env.chart_list,
                        value: '',
                        size: 0,
                        type: '',
                        limit: this.limit,
                        offset: 0,
                        order: 1,
                        by: ''
                    };
                    this.clickKpi = 1;
                }, 500)
            } else {
                volt.tak('page-loader').style.display = '';
            }
        }
    },
    data() {
        return {
            env: {},
            title: '',
            glossaire: {},
            user: {},
            year: '',
            limit: 200,
            logo: 'logo_touriz.png',
            clickKpi: 0,
            clickSync: 0,
            clickAnnonce: 0,
            annonceId: '',
            x_token: '',
            entity: '',
            entities_user: [],
            list: {},
            listPop: {},
            kpis: [],
            kpis_export_1: [],
            kpis_export_2: [],
            searchOptions: [],
            dashboard: 'observatoire',
            city_code: '',
            entity_select: {},
            datasMap: {center: ''},
            irisMap: {center: ''},
            annonce: {},
            annonces: [],
            map: {},
            mapPop: {},
            layer: {},
            layerAnnonces: {},
            layerAnnoncesConf: {},
            layerAnnoncesAVerif: {},
            layerAnnoncesNconf: {},
            models: [],
            entetes: [],
            values: [],
            like: "",
            feature: {},
            offset: 0,
            data_like: {},
            size: 0,
            ids: [],
            cluster: '',
            id_cluster: '',
            map_filter: 1,
            points: '',
            specif: 'elan',
            list_id: '',
            user_list: [],
            entity_list: [],
            datas_exists: true,
            error_page: false,
            error_reason: {},
            list_class: [],
            videoView: false
        }
    }
}
</script>

<style>
html {
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    height: 100%;
    margin: 0;
    padding: 0;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    width: 100%;
    height: 100%;
}

.datas_no_exist {
    border: solid 1px #f3b61b;
    border-radius: 20px;
    grid-column-start: 2;
    grid-column-end: 20;
    grid-row-start: 7;
    grid-row-end: 15;
    background-color: #f3b61b55;
    color: #382a05;
    font-size: 25px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.detail-content-floatmenu {
    margin-top: 5px;
    border-radius: 20px;
    padding: 10px;
    transition: 0.5s;
}

.base-grid-connect {
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-template-rows: repeat(20, 1fr);
    height: 100%;
    max-height: 100%;
    background-color: #eeeeee;
    overflow: hidden;
}

.base-grid {
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-gap: 1px;
    grid-template-rows: repeat(20, 1fr);
    height: 100%;
    max-height: 100%;
    background-color: #eeeeee;
    overflow: hidden;
}

.base-grid-menu {
    grid-column-start: 1;
    grid-column-end: 21;
    grid-row-start: 1;
    grid-row-end: 2;
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-gap: 1px;
    height: 100%;
    max-height: 100%;
    background-color: #eeeeee;
}

.title-kpi-components {
    grid-column-start: 1;
    grid-column-end: 21;
    text-align: start;
    margin: 20px;
    border-bottom: solid 1px #7a7a7a;
}

.base-grid-kpi {
    padding: 2px;
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 2;
    grid-row-end: 21;
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-gap: 1px;
    grid-template-rows: repeat(20, 1fr);
    height: 100%;
    max-height: 100%;
    background-color: #fefefe;
    overflow: auto;
}

.base-grid-admin {
    padding: 2px;
    grid-column-start: 1;
    grid-column-end: 21;
    grid-row-start: 2;
    grid-row-end: 21;
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-gap: 1px;
    grid-template-rows: repeat(20, 1fr);
    height: 100%;
    max-height: 100%;
    background-color: #fefefe;
    overflow: auto;
}

.base-grid-map {
    padding: 2px;
    grid-column-start: 6;
    grid-column-end: 21;
    grid-row-start: 2;
    grid-row-end: 21;
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-gap: 1px;
    grid-template-rows: repeat(20, 1fr);
    height: 100%;
    max-height: 100%;
    background-color: #eeeeee;
}

.base-grid-chart {
    padding: 2px;
    grid-column-start: 1;
    grid-column-end: 21;
    grid-row-start: 2;
    grid-row-end: 21;
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-gap: 1px;
    grid-template-rows: repeat(20, 1fr);
    height: 100%;
    max-height: 100%;
    background-color: #fdfdfd;
}

.loader-affichage-list {
    grid-column-start: 10;
    grid-column-end: 11;
    grid-row-start: 9;
    grid-row-end: 9;
}

*::-webkit-scrollbar {
    width: 4px;
    height: 8px;
    background-color: #f3f3dc; /* or add it to the track */
    box-shadow: inset -1px -2px 3px #aaa;
}

*::-webkit-scrollbar-thumb {
    background-color: #f3b61b99;
}

* {
    scrollbar-color: #f3b61b rgba(223, 222, 225, 0.7);
    scrollbar-width: thin;
}

.menu-map-ol {
    background-color: #525252aa;
    border-radius: 5px;
    position: relative;
    /*right: -700px;*/
    color: #fefefe;
    height: 25px;
    /*padding: 20px;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    transition: 50ms;
}

.menu-choices-map-ol {
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
}

.burger-menu-map {
    position: relative;
    cursor: pointer;
    /*width: 50px;*/
    /*height: 50px;*/
    padding: 5px;
    background-color: #3e160355;
    border-radius: 10px;
    margin: 5px;
}

.menu-close-map-ol {
    display: flex;
    flex-direction: row;
    justify-content: end;
    padding-top: 10px;
    width: 90%;
    cursor: pointer;
}

.closeMenu {

}

.close-kpis-specif {
    float: right;
    font-size: 20px;
    /*color: #f43249;*/
    border-radius: 15px;
    padding: 3px;
    margin-bottom: 6px;
    cursor: pointer;
}

.close-kpis-specif:active {
    box-shadow: inset 1px 1px 5px #7a7a7a;
    font-size: 19px;
    margin-bottom: 7px;
}

.alert-red {
    display: none;
    width: 90%;
    color: #7a0202;
    border: solid 2px #7a0202;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    background-color: #f74560;
    margin: 2% 0 2% 5%;
    padding: 5px 5px 5px 5px;
    border-radius: 5px;
}

.msg-green {
    display: none;
    width: 90%;
    color: #175102;
    border: solid 2px #175102;
    font-weight: bold;
    background-color: #07bc50;
    margin: 2% 0 2% 5%;
    padding: 5px 5px 5px 5px;
    border-radius: 5px;
}

.base-grid-kpi-co {
    padding: 2px;
    grid-column-start: 1;
    grid-column-end: 21;
    grid-row-start: 1;
    grid-row-end: 21;
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-gap: 1px;
    grid-template-rows: repeat(20, 1fr);
    height: 100%;
    max-height: 100%;
    background-color: #fefefe;
    overflow: auto;
}

.headers-kpi-intro-div {
    display: grid;
    grid-column-start: 1;
    grid-column-end: 21;
    text-align: start;
    padding: 0 30px 0 30px;
    margin-bottom: 15px;
}

.export-kpis-intro-btn {
    display: flex;
    grid-column-start: 22;
    grid-column-end: 22;
    padding: 15px;
    height: 40px;
    position: relative;
    top: 30px;
    left: 30px;
}

.export-print-kpis {
    background-color: #d6d6d6;
    border-radius: 5px;
    font-size: 20px;
    cursor: pointer;
    padding: 10px;
}

.export-print-kpis:active {
    box-shadow: inset 1px 1px 5px #7a7a7a;
}

.headers-kpi-PAR-div {
    display: grid;
    grid-column-start: 1;
    grid-column-end: 21;
    text-align: start;
    padding: 0 30px 0 30px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.headers-kpi-PAR-div-export {
    display: none;
    grid-column-start: 1;
    grid-column-end: 21;
    text-align: start;
    padding: 0 10px 0 10px;
    margin-top: 20px;
    margin-bottom: 20px;
}
#title-log-touriz-export{
    display: flex;
    width: 95%;
    justify-content: start;
    margin-top: 20px;
    margin-bottom: 20px;
    align-items: center;
}
#logo-touriz-img-export {
    width: 25%;
    height: 100%;
    margin-right: 30px;
}
#title-touriz-export{
    font-size: 30px;
    font-weight: bold;
}
#subtitle-log-touriz-export{
    display: flex;
    width: 95%;
    justify-content: end;
    margin-top: 20px;
    margin-bottom: 50px;
    align-items: center;
}
#subtitle-touriz-export{
    font-size: 18px;
}
.headers-kpi-PAR-text {
    background-color: #eeeeee;
}

.headers-kpi-PAR-title {
    margin: 10px;
}

.headers-kpi-intro-text-map {
    display: flex;
    justify-content: space-between;
}

.headers-kpi-intro-text {
}

.headers-kpi-intro-map {
    width: 800px;
    height: 100px;
    box-shadow: inset 1px 1px 5px #7a7a7a;
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-template-rows: repeat(20, 1fr);
}

.headers-kpi-intro-title {

}

.headers-kpi-intro-p {
    margin: 2px;
}

.kpi-content-div {
    grid-column-start: 1;
    grid-column-end: 21;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 1px;
    padding: 20px;
    height: 100%;
    max-height: 100%;
    background-color: #fefefe;
}

.page-export {
    grid-column-start: 1;
    grid-column-end: 13;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 1px;
    height: 100%;
    max-height: 100%;
    background-color: #fefefe;
}

.headers-kpi-PAR-text-export {
    background-color: #eeeeee;
    margin-bottom: 50px;
}

.header-kpi-intro-bnts {
    position: sticky;
    top: -5px;
    z-index: 2000;
    grid-column-start: 1;
    grid-column-end: 21;
    padding: 0 20px 20px 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    background: linear-gradient(#fefefe 70%, #fefefe11);
}

.kpi-intro-bnts {
    width: 280px;
    margin: 10px;
    border: none;
    font-size: 15px;
    padding: 2px;
    border-radius: 5px;
    cursor: pointer;
    background-color: #d6d6d6;
}

.kpi-intro-bnts:active {
    box-shadow: inset 1px 1px 5px #7a7a7a;
}

.kpi-intro-bnts-back {
    width: 40px;
    margin: 10px;
    border: none;
    font-size: 15px;
    padding: 2px;
    border-radius: 5px;
    cursor: pointer;
    background-color: #d6d6d6;
}

.kpi-intro-bnts-back:active {
    box-shadow: inset 1px 1px 5px #7a7a7a;
}

.kpi-intro-title-map {
    width: 300px;
    text-align: start;
    border: none;
    font-size: 15px;
    padding: 2px;
    border-bottom: solid 1px #f3b61b;
    border-left: solid 1px #f3b61b;
    display: flex;
    align-items: center;
    height: 40px;
    justify-content: space-evenly;
}

.headers-kpi-PAR-detail {
    overflow: auto;
    padding: 10px;
}

.bw-map {
    filter: grayscale(100%);
}

.ol-zoom {
    position: relative;
    top: 85%;
    display: flex;
    justify-content: end;
}

.ol-zoom-in {
    background-color: #7a7a7a77;
    margin: 5px;
    width: 50px;
    height: 50px;
    border-radius: 10px;
    outline: none;
    border: none;
    font-size: 20px;
}

.ol-zoom-out {
    margin: 5px;
    width: 50px;
    height: 50px;
    border-radius: 10px;
    background-color: #7a7a7a77;
    outline: none;
    border: none;
    font-size: 20px;
}

.popup-map {
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    min-width: 200px;
    min-height: 80px;
    padding: 5px;
    background: #FFFFFF;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.popup-map::after {
    content: '';
    position: absolute;
    bottom: -18px;
    left: 10px;
    border-width: 20px 20px 0;
    border-style: solid;
    border-color: #FFFFFF transparent;
    display: block;
    width: 0;
}

@media (max-width: 1250px) {
    .kpi-intro-bnts {
        display: none;
    }
}

@media (max-width: 800px) {
    .headers-kpi-intro-div {
        grid-row-start: 1;
    }

    .headers-kpi-PAR-div {
        grid-row-start: 9;
    }

    .header-kpi-intro-bnts {
        display: none;
    }

    .kpi-content-div {
        grid-row-start: 16;
    }

    .export-print-kpis {
        display: none !important;
    }

    .base-grid-chart {
        overflow: auto;
    }
}
</style>
