<template>
    <div id="export-loader" class="main-content">
      <div id="example-5" class="examples">
        <h3>Téléchargement des annonces</h3>
        <div class="cssProgress">
          <div class="progress3">
            <div class="cssProgress-bar cssProgress-warning cssProgress-active" :data-percent="value1" :style="'width: '+value1+'%;'">
              <span class="cssProgress-label">{{ value1 }}%</span>
            </div>
          </div>
        </div>
        <h3>Construction du fichier d'export</h3>
        <div class="cssProgress">
          <div class="progress3">
            <div class="cssProgress-bar cssProgress-info cssProgress-active" :data-percent="value2" :style="'width: '+value2+'%;'">
              <span class="cssProgress-label">{{ value2 }}%</span>
            </div>
          </div>
        </div>
        <h3>Téléchargement du fichier d'export</h3>
        <div class="cssProgress">
          <div class="progress3">
            <div class="cssProgress-bar cssProgress-success cssProgress-active" :data-percent="value3" :style="'width: '+value3+'%;'">
              <span class="cssProgress-label">{{ value3 }}%</span>
            </div>
          </div>
        </div>
      </div><!-- @end #example-5 -->
    </div>
</template>

<script>
export default {
  name: "ExportLoader",
  props :{
    value1 : Number,
    value2 : Number,
    value3 : Number,
  },
  watch:{
    value1(){
      if(this.value1 > 0){
        this.display = 'block';
      }else{
        this.display = 'none';
      }
    }
  },
  mounted() {
    if(this.value1 > 0){
      this.display = 'block';
    }
  },
  data(){
    return{
      display : 'none'
    }
  }
}
</script>

<style scoped>
@import url(https://fonts.googleapis.com/css?family=Roboto);
/* -----------------------------------------------------
	Body
-------------------------------------------------------- */

a {
  color: #ffa000;
  text-decoration: none;
  outline: none;
}
a:hover {
  text-decoration: underline;
}
#export-loader{
  display: v-bind(display);
}
.main-content{
  position: fixed;
  background-color: #fefefeee;
  top: 0;
  left: 0;
  width: 90%;
  height: 100%;
  padding-top: 15%;
  padding-right: 5%;
  padding-left: 5%;
  z-index: 4000;
}

/* -----------------------------------------------------
	Buttons
-------------------------------------------------------- */
.btn1,
.btn {
  color: #FFF;
  display: inline-block;
  position: relative;
  text-align: center;
  text-decoration: none;
  vertical-align: baseline;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  border-radius: 3px;
}
.btn1:hover,
.btn:hover {
  text-decoration: none;
}

.gitBtn,
.penBtn {
  height: 38px;
  font-size: 1.1em;
  line-height: 38px;
  margin-right: 10px;
  padding: 0px 22px;
  border: 1px solid #FFA500;
  background-color: #FFA500;
  box-shadow: 0px 2px rgba(0, 0, 0, 0.157);
}
.gitBtn:last-child,
.penBtn:last-child {
  margin-right: 0;
}

.btn {
  height: 30px;
  font-size: 12px;
  font-weight: bold;
  line-height: 30px;
  padding: 0px 14px;
}
.btn.btn-primary {
  border: 1px solid #FFA500;
  background-color: #FFA500;
}
.btn.btn-primary:hover {
  border: 1px solid #f09b00;
  background-color: #f09b00;
}

/* -----------------------------------------------------
	CSS Progress Bars
-------------------------------------------------------- */
.cssProgress {
  width: 100%;
  margin-bottom: 20px;
}
.cssProgress .progress1,
.cssProgress .progress2,
.cssProgress .progress3 {
  position: relative;
  overflow: hidden;
  width: 100%;
  font-family: "Roboto", sans-serif;
}
.cssProgress .cssProgress-bar {
  display: block;
  float: left;
  width: 0%;
  height: 100%;
  background: #3798d9;
  box-shadow: inset 0px -1px 2px rgba(0, 0, 0, 0.1);
  transition: width 0.8s ease-in-out;
}
.cssProgress .cssProgress-label {
  position: absolute;
  overflow: hidden;
  left: 0px;
  right: 0px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.7em;
  text-align: center;
  text-shadow: 0px 1px rgba(0, 0, 0, 0.3);
}
.cssProgress .cssProgress-info {
  background-color: #9575cd !important;
}
.cssProgress .cssProgress-danger {
  background-color: #ef5350 !important;
}
.cssProgress .cssProgress-success {
  background-color: #66bb6a !important;
}
.cssProgress .cssProgress-warning {
  background-color: #ffb74d !important;
}
.cssProgress .cssProgress-right {
  float: right !important;
}
.cssProgress .cssProgress-label-left {
  margin-left: 10px;
  text-align: left !important;
}
.cssProgress .cssProgress-label-right {
  margin-right: 10px;
  text-align: right !important;
}
.cssProgress .cssProgress-label2 {
  display: block;
  margin: 2px 0;
  padding: 0 8px;
  font-size: 0.8em;
}
.cssProgress .cssProgress-label2.cssProgress-label2-right {
  text-align: right;
}
.cssProgress .cssProgress-label2.cssProgress-label2-center {
  text-align: center;
}
.cssProgress .cssProgress-stripes,
.cssProgress .cssProgress-active,
.cssProgress .cssProgress-active-right {
  background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.125) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.125) 50%, rgba(255, 255, 255, 0.125) 75%, transparent 75%, transparent);
  background-size: 35px 35px;
}
.cssProgress .cssProgress-active {
  -webkit-animation: cssProgressActive 2s linear infinite;
  animation: cssProgressActive 2s linear infinite;
}
.cssProgress .cssProgress-active-right {
  -webkit-animation: cssProgressActiveRight 2s linear infinite;
  animation: cssProgressActiveRight 2s linear infinite;
}
@-webkit-keyframes cssProgressActive {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 35px 35px;
  }
}
@keyframes cssProgressActive {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 35px 35px;
  }
}
@-webkit-keyframes cssProgressActiveRight {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -35px -35px;
  }
}
@keyframes cssProgressActiveRight {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -35px -35px;
  }
}

/* -----------------------------------------------------
	Progress Bar 1
-------------------------------------------------------- */
.progress1 {
  background-color: #EEE;
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.2);
}
.progress1 .cssProgress-bar {
  height: 18px;
}
.progress1 .cssProgress-label {
  line-height: 18px;
}

/* -----------------------------------------------------
	Progress Bar 2
-------------------------------------------------------- */
.progress2 {
  background-color: #EEE;
  border-radius: 9px;
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.2);
}
.progress2 .cssProgress-bar {
  height: 18px;
  border-radius: 9px;
}
.progress2 .cssProgress-label {
  line-height: 18px;
}

/* -----------------------------------------------------
	Progress Bar 3
-------------------------------------------------------- */
.progress3 {
  width: auto !important;
  padding: 4px;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}
.progress3 .cssProgress-bar {
  height: 16px;
  border-radius: 3px;
}
.progress3 .cssProgress-label {
  line-height: 16px;
}

/* -----------------------------------------------------
	Progress Bar 4
-------------------------------------------------------- */
.progress4 {
  position: relative;
  width: 100%;
  background-color: #EEE;
}
.progress4.cssProgress-bg {
  background-color: #bbdefb !important;
}
.progress4.cssProgress-bg-info {
  background-color: #d1c4e9 !important;
}
.progress4.cssProgress-bg-danger {
  background-color: #ffcdd2 !important;
}
.progress4.cssProgress-bg-success {
  background-color: #c8e6c9 !important;
}
.progress4.cssProgress-bg-warning {
  background-color: #ffecb3 !important;
}
.progress4 .cssProgress-bar {
  display: block;
  float: none;
  width: 0%;
  height: 4px;
  background: #3798d9;
}
.progress4 .cssProgress-bar.cssProgress-lg {
  height: 6px;
}
.progress4 .cssProgress-bar.cssProgress-2x {
  height: 8px;
}
.progress4 .cssProgress-bar.cssProgress-3x {
  height: 10px;
}
.progress4 .cssProgress-bar.cssProgress-4x {
  height: 12px;
}
.progress4 .cssProgress-bar.cssProgress-5x {
  height: 14px;
}
.progress4 .cssProgress-bar.cssProgress-glow {
  box-shadow: 5px 0px 15px 0px #3798D9;
}
.progress4 .cssProgress-bar.cssProgress-glow.cssProgress-info {
  box-shadow: 5px 0px 15px 0px #9575cd;
}
.progress4 .cssProgress-bar.cssProgress-glow.cssProgress-danger {
  box-shadow: 5px 0px 15px 0px #ef5350;
}
.progress4 .cssProgress-bar.cssProgress-glow.cssProgress-success {
  box-shadow: 5px 0px 15px 0px #66bb6a;
}
.progress4 .cssProgress-bar.cssProgress-glow.cssProgress-warning {
  box-shadow: 5px 0px 15px 0px #ffb74d;
}
.progress4 .cssProgress-bar.cssProgress-glow-active {
  -webkit-animation: cssProgressGlowActive1 3s linear infinite;
  animation: cssProgressGlowActive1 3s linear infinite;
}
.progress4 .cssProgress-bar.cssProgress-glow-active.cssProgress-info {
  -webkit-animation: cssProgressGlowActive2 3s linear infinite;
  animation: cssProgressGlowActive2 3s linear infinite;
}
.progress4 .cssProgress-bar.cssProgress-glow-active.cssProgress-danger {
  -webkit-animation: cssProgressGlowActive3 3s linear infinite;
  animation: cssProgressGlowActive3 3s linear infinite;
}
.progress4 .cssProgress-bar.cssProgress-glow-active.cssProgress-success {
  -webkit-animation: cssProgressGlowActive4 3s linear infinite;
  animation: cssProgressGlowActive4 3s linear infinite;
}
.progress4 .cssProgress-bar.cssProgress-glow-active.cssProgress-warning {
  -webkit-animation: cssProgressGlowActive5 3s linear infinite;
  animation: cssProgressGlowActive5 3s linear infinite;
}
@-webkit-keyframes cssProgressGlowActive1 {
  0%, 100% {
    box-shadow: 5px 0px 15px 0px #3798D9;
  }
  45% {
    box-shadow: 1px 0px 4px 0px #3798D9;
  }
}
@keyframes cssProgressGlowActive1 {
  0%, 100% {
    box-shadow: 5px 0px 15px 0px #3798D9;
  }
  45% {
    box-shadow: 1px 0px 4px 0px #3798D9;
  }
}
@-webkit-keyframes cssProgressGlowActive2 {
  0%, 100% {
    box-shadow: 5px 0px 15px 0px #9575cd;
  }
  45% {
    box-shadow: 1px 0px 4px 0px #9575cd;
  }
}
@keyframes cssProgressGlowActive2 {
  0%, 100% {
    box-shadow: 5px 0px 15px 0px #9575cd;
  }
  45% {
    box-shadow: 1px 0px 4px 0px #9575cd;
  }
}
@-webkit-keyframes cssProgressGlowActive3 {
  0%, 100% {
    box-shadow: 5px 0px 15px 0px #ef5350;
  }
  45% {
    box-shadow: 1px 0px 4px 0px #ef5350;
  }
}
@keyframes cssProgressGlowActive3 {
  0%, 100% {
    box-shadow: 5px 0px 15px 0px #ef5350;
  }
  45% {
    box-shadow: 1px 0px 4px 0px #ef5350;
  }
}
@-webkit-keyframes cssProgressGlowActive4 {
  0%, 100% {
    box-shadow: 5px 0px 15px 0px #66bb6a;
  }
  45% {
    box-shadow: 1px 0px 4px 0px #66bb6a;
  }
}
@keyframes cssProgressGlowActive4 {
  0%, 100% {
    box-shadow: 5px 0px 15px 0px #66bb6a;
  }
  45% {
    box-shadow: 1px 0px 4px 0px #66bb6a;
  }
}
@-webkit-keyframes cssProgressGlowActive5 {
  0%, 100% {
    box-shadow: 5px 0px 15px 0px #ffb74d;
  }
  45% {
    box-shadow: 1px 0px 4px 0px #ffb74d;
  }
}
@keyframes cssProgressGlowActive5 {
  0%, 100% {
    box-shadow: 5px 0px 15px 0px #ffb74d;
  }
  45% {
    box-shadow: 1px 0px 4px 0px #ffb74d;
  }
}
</style>