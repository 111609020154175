<template>
  <div class="pop-params" id="pop-params-div">
    <div id="pop-params-content">
      <LinkSelect v-for="choice in choices_select"
                  :key="choice"
                  :name="choice.name"
                  :choices="choice.choices"
                  :year = "year"
                  :limit = "limit"
                  :entity = "entity"
                  @callYear = "callYear"
                  @callResult = "callResult"
                  @callEntity = "callEntity"
      />
      <div class="separateur-menu"></div>
      <menu-link v-for="choice in choices_link"
          :key="choice"
          :name="choice.name"
          :choices="choice.choices"
          @callYear = "callYear"
          @callResult = "callResult"
          @callEntity = "callEntity"
          @callMapFilter = "callMapFilter"
          :year = "year"
          :limit = "limit"
          :entity = "entity"
          :map_filter = "map_filter"
      />
    </div>
  </div>
</template>

<script>
import MenuLink from "@/components/MenuLink";
import LinkSelect from "@/components/LinkSelect";
export default {
  name: "PopParams",
  components: {LinkSelect, MenuLink},
  emits: ["callYear", "callResult", "callEntity","callMapFilter"],
  props: {
    choices_select : Object,
    choices_link : Object,
    year : String,
    limit : Number,
    entity : String,
    map_filter : Number,
    specif : String
  },
  methods : {
    callYear(){setTimeout(()=>{ this.$emit('callYear');},100);},
    callResult(){setTimeout(()=>{ this.$emit('callResult');},100);},
    callEntity(){setTimeout(()=>{ this.$emit('callEntity');},100);},
    callMapFilter(){setTimeout(()=>{ this.$emit('callMapFilter');},100);},
  }
}
</script>

<style scoped>
.pop-params{
  position: fixed;
  top: 70px;
  left: 85%;
  width: 15%;
  background-color: #eceaea;
  z-index: 2500;
  border-left: solid 2px #f3b61b;
  padding-left: 15px;
  border-radius: 0 0 10px 10px;
  transition: 0.2s;
  height: 0;
  overflow: auto;
}
#pop-params-content{
  display: flex;
  flex-direction: column;
  justify-content: start;
}
.separateur-menu{
  width: 100%;
  height: 5px;
  border-bottom: solid 1px #7a7a7a55;
  border-top: solid 1px #7a7a7a55;
  margin: 10px 0 20px 0;
}
@media (max-width: 1200px) {
  .pop-params{
    position: fixed;
    top: 100px;
    left: 70%;
    width: 100%;
  }
}
@media (max-width: 800px) {
  .pop-params{
    position: fixed;
    top: 100px;
    left: 0;
    width: 100%;
  }
}
</style>