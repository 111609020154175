<template>
  <div class="kpi-loader" :id="'kpi-loader-'+title" >
    <div class="loader" >
    </div>
  </div>
</template>

<script>
export default {
  name: "KpiLoader",
  props: {
    title : String
  }
}
</script>

<style scoped>
.kpi-loader{
  display: none;
  width: 100%;
  justify-content: center;
}
.loader {
  width: 120px;
  height: 120px;
  border-left: 5px solid #f3b61b;
  border-radius: 50%;
  animation: right5231 0.9s linear infinite;
}

.loader::before,
.loader::after {
  content: "";
  width: 100px;
  height: 100px;
  display: block;
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  border-right: 5px solid #f4d37a;
  border-radius: 50%;
  animation: left036 0.9s linear infinite;
}

.loader::after {
  width: 60px;
  height: 60px;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 0;
  border-top: 5px solid #b58408;
  animation: none;
}

@keyframes right5231 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes left036 {
  from {
    transform: rotate(720deg);
  }

  to {
    transform: rotate(0deg);
  }
}
</style>