<template>
  <div class="line-block">
    <div class="kpi-subtitle" :style="style.title">{{title}}</div>
    <div class="line-chart">
      <canvas :id="'line-'+title"></canvas>
    </div>
  </div>
</template>

<script>
// import volt from "@/volt";
import * as charts_obs from "@/charts_obs";
export default {
  name: "LineBlock",
  props : {
    title : String,
    style : Object,
    datas : Object,
    type : String
  },
  data(){
    return{
      chart : {}
    }
  },
  mounted() {
    let data_values = [];
    this.datas.forEach((data)=>{
      data_values.push(data.value);
    })
    if(this.type === 'multi'){
      this.chart = charts_obs.multi_line(this.title, data_values, this.style.legend);
    }else{
      this.chart = charts_obs.line(this.title, data_values, this.style.legend);
    }
  }
}
</script>

<style scoped>
.line-block{
  /*max-height: 90%;*/
  /*min-width: 90%;*/
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.line-legend{
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 20%;
}
.legend-chart{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.line-chart{
  width: 100%;
  height: 80%;
}
.kpi-subtitle{
  text-align: start;
  font-size: 14px;
  font-weight: bold;
  padding: 15px 15px 10px 15px;
  display: flex;
  flex-direction: row;
}
</style>