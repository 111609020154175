/* eslint-disable */
const volt = require("./volt");
const {Map, View, Overlay} =require("ol");
const {defaults, ZoomSlider} =require("ol/control");
const {defaults : defaultInteractions} =require("ol/interaction");
const {Tile, Vector : VectorLayer, Image : ImageLayer} =require("ol/layer");
const {OSM, Vector : VectorSource, ImageWMS} =require("ol/source");
const {Style, Circle, Fill, Stroke, Text, Icon} =require("ol/style");
const olFunction = require("@/olFunction");
const {transform} = require("ol/proj");
const {getVectorContext} = require("ol/render");

module.exports.mapOlMin= {};
module.exports.callMap= function(el, center, zoom, back, m) {
    const menuDiv = document.createElement("div");
    menuDiv.classList.add('popup-map');
    menuDiv.id = 'popup-map-'+ el;
    if(volt.tak('map-' + el))volt.tak('map-' + el).appendChild(menuDiv);
    // //creation de l'overlay menu
    // const menuDiv = document.createElement("div");
    // menuDiv.classList.add('menu-map-ol');
    // menuDiv.id = 'menu-map-'+ el;
    // const menuChoice = document.createElement("div");
    // menuChoice.classList.add('menu-choices-map-ol');
    // // menuChoice.innerText = 'huhreoivhnhervpknrepkhjnvk';
    // const closeMenu = document.createElement("div");
    // closeMenu.classList.add('menu-close-map-ol');
    // closeMenu.innerText = 'X';
    // closeMenu.setAttribute('data-menu', el);
    // menuDiv.appendChild(closeMenu);
    // menuDiv.appendChild(menuChoice);
    // const menu = new Overlay({
    //     id : 'overlay-menu-map-'+el,
    //     element : menuDiv,
    //     position : [0],
    //     autoPan: {
    //         animation: {
    //             duration: 250,
    //         },
    //     },
    // });
    // const menuBurger = document.createElement('div');
    // menuBurger.classList.add('burger-menu-map');
    // menuBurger.innerHTML = '<i class="fas fa-bars" style="font-size: 30px"></i>';
    // menuBurger.setAttribute('data-menu', el);
    // const burger = new Overlay({
    //     id : 'overlay-burger-menu-map-'+el,
    //     element : menuBurger,
    //     position : [0]
    // });
////////////////////////////////////////////////////////////////////////////////////////////

    if(zoom === undefined)zoom = 14;
    let point = '';
    if(center === undefined || center === ''){
        point = {
            type: "FeatureCollection",
            features: [
                {
                    type:"Feature",
                    geometry:{
                        type:"Point",
                        coordinates: [2.3470081, 48.8590081]
                    }
                }
            ]
        };
    }else{
        point = {
            type: "FeatureCollection",
            features: [center]
        };
    }
    olFunction.transformProjection(point);
    if(m === undefined || m === '') {
        let lay =  [
            new Tile
            ({
                source: new OSM(),
            }),
        ]
        if(back === 'empty'){
            lay = [
            new Tile({
                className: 'bw-map',
                source: new OSM(),
            })]
        }
        this.mapOlMin[el] = new Map({
            target: 'map-' + el,
            layers: lay,
            view: new View({
                center: point['features'][0]['geometry']['coordinates'],
                zoom: zoom,
            }),
            controls: defaults({
                attribution: false,
                zoom: true,
                rotate: false,
            }),
            interactions: defaultInteractions({
                mouseWheelZoom:false
            })
            // overlays: [menu, burger],
        });
        // console.log(this.mapOlMin.getOverlays());
        // this.mapOlMin.getOverlayById('overlay-menu-map-'+el).style.left='50px';
    }else{
        this.mapOlMin[el] = m;
        m.getView().setCenter(point['features'][0]['geometry']['coordinates']);
        m.getView().setZoom(zoom);
        m.setTarget('map-'+el);
        // console.log(m.getControls());
        m.getControls().removeAt(0);
        m.getControls().removeAt(0);
        m.getControls().removeAt(0);
    }
    volt.maps[el] = module.exports.mapOlMin[el];
    // if(this.mapOlMin.getSize() !== undefined){
    //     setTimeout(()=>{
    //         menuDiv.style.left = (this.mapOlMin.getSize()[0] - menuDiv.offsetWidth) + 'px';
    //         menuDiv.style.height = this.mapOlMin.getSize()[1] + 'px';
    //         menuDiv.style.display = 'none';
    //         menuBurger.style.left = (this.mapOlMin.getSize()[0] - 60) + 'px';
    //     },100)
    //     menuBurger.addEventListener('click',function(){
    //         volt.annonce = '';
    //         menuDiv.style.display = '';
    //         this.style.display = 'none';
    //     });
    //     closeMenu.addEventListener('click',function(){
    //         volt.annonce = '';
    //         menuDiv.style.display = 'none';
    //         menuBurger.style.display = '';
    //     });
    // }


    module.exports.mapOlMin[el].on('pointerdrag', function(evt){
        volt.annonce = '';
    });
    let selected = null;
    module.exports.mapOlMin[el].on('pointermove', function(evt){
        if (selected !== null) {
            selected.setStyle(undefined);
            selected = null;
        }
        var hit = module.exports.mapOlMin[el].forEachFeatureAtPixel(evt.pixel, function(feature){

            // console.log(feature.getProperties());
            const coordinate = evt.coordinate;
            popup.setPosition(coordinate);
            if(feature.getProperties().iris_name !== undefined) {
                selected = feature;
                selectStyle.getFill().setColor('#eeeeee77');
                feature.setStyle(selectStyle);
                let nbr_annonces ="Aucune annonce";
                let taux_elan ="0";
                let taux_vs_logement ="0";
                if(feature.getProperties().annonces_elan)nbr_annonces = feature.getProperties().annonces_elan+' annonces';
                if(feature.getProperties().taux_annonces_vs_logements)taux_vs_logement = volt.number_format(Number(feature.getProperties().taux_annonces_vs_logements).toFixed(1))
                if(feature.getProperties().taux_annonces_elan)taux_elan = volt.number_format(Number(feature.getProperties().taux_annonces_elan).toFixed(1))

                let html = '<h4 style="margin: 5px 0 5px 0">Quartier "' + feature.getProperties().iris_name + '"</h4>';
                html = html + '<p style="margin: 0 0 5px 0; font-size: 10px">(N° ' + feature.getProperties().iris_code.substring(5,9) + ')</p>';
                html = html + '<p style="margin: 0 0 5px 0">' + nbr_annonces + '</p>';
                if (el === 'iris') {
                    html = html + '<p style="margin: 0 0 5px 0">' + taux_vs_logement + ' %</p>';
                } else if (el === 'global') {
                    html = html + '<p style="margin: 0 0 5px 0">' + taux_elan + ' %</p>';
                }
                popup.getElement().innerHTML = html;
            }else if(feature.getProperties().arrondissement !== undefined) {
                selected = feature;
                selectStyle.getFill().setColor('#eeeeee77');
                feature.setStyle(selectStyle);
                let nbr_annonces ="Aucune annonce";
                let taux_annonces_arr ="0";
                let taux_vs_logement_arr ="0";
                if(feature.getProperties().annonces_elan)nbr_annonces = feature.getProperties().annonces_elan +' annonces';
                if(feature.getProperties().taux_annonces_vs_logements_arr)taux_vs_logement_arr = volt.number_format(Number(feature.getProperties().taux_annonces_vs_logements_arr).toFixed(1))
                if(feature.getProperties().taux_annonces_arr)taux_annonces_arr = volt.number_format(Number(feature.getProperties().taux_annonces_arr).toFixed(1))

                let html = '<h4 style="margin: 5px 0 5px 0">' + feature.getProperties().arrondissement + '</h4>';
                html = html + '<p style="margin: 0 0 5px 0; font-size: 10px">(' + feature.getProperties().code_insee + ')</p>';
                html = html + '<p style="margin: 0 0 5px 0">' + nbr_annonces +'</p>';
                if (el === 'iris') {
                    html = html + '<p style="margin: 0 0 5px 0">' + taux_vs_logement_arr + ' %</p>';
                } else if (el === 'global') {
                    html = html + '<p style="margin: 0 0 5px 0">' + taux_annonces_arr + ' %</p>';
                }
                popup.getElement().innerHTML = html;
            }else if(feature.getProperties().entity !== undefined && feature.getProperties().ept_rapport !== undefined && feature.getProperties().ept_rapport === 1) {
                selected = feature;
                selectStyle.getFill().setColor('#eeeeee77');
                feature.setStyle(selectStyle);
                let nbr_annonces = "Aucune annonce";
                let taux_annonces_ept = "0";
                let taux_vs_logement_ept = "0";
                if (feature.getProperties().annonces_elan) nbr_annonces = feature.getProperties().annonces_elan + ' annonces';
                if (feature.getProperties().taux_annonces_vs_logements) taux_vs_logement_ept = volt.number_format(Number(feature.getProperties().taux_annonces_vs_logements).toFixed(1))
                if (feature.getProperties().taux_annonces_elan) taux_annonces_ept = volt.number_format(Number(feature.getProperties().taux_annonces_elan).toFixed(1))

                let html = '<h4 style="margin: 5px 0 5px 0">' + feature.getProperties().nom + '</h4>';
                html = html + '<p style="margin: 0 0 5px 0; font-size: 10px">(' + feature.getProperties().code_insee + ')</p>';
                html = html + '<p style="margin: 0 0 5px 0">' + nbr_annonces + '</p>';
                if (el === 'iris') {
                    html = html + '<p style="margin: 0 0 5px 0">' + taux_vs_logement_ept + ' %</p>';
                } else if (el === 'global') {
                    html = html + '<p style="margin: 0 0 5px 0">' + taux_annonces_ept + ' %</p>';
                }
                popup.getElement().innerHTML = html;
            }else{
                popup.setPosition(undefined);
            }
            return true;
        });
        if(hit){
            volt.tak(module.exports.mapOlMin[el].getTarget()).style.cursor =  'pointer';
        }else{
            popup.setPosition(undefined);
            volt.tak(module.exports.mapOlMin[el].getTarget()).style.cursor =  '';
        }
    });


    this.mapOlMin[el].getLayers().forEach(function (layer) {
        if (layer !== undefined && layer.getSource() instanceof VectorSource) {
            // console.log(layer.values_);
        }
    });
    module.exports.mapOlMin[el].on('singleclick', function (evt) {
        volt.annonce = '';
        module.exports.mapOlMin[el].forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
            if(layer.values_.id === 'quartier'){
                volt.feature = {};
                let feat = {
                    type : "Feature",
                    properties : feature.getProperties(),
                    geometry : {
                        type : feature.getGeometry().getType(),
                        coordinates : feature.getGeometry().getCoordinates()
                    }
                }
                delete feat.properties.geometry;
                olFunction.transformProjection(feat, 'EPSG:3857', 'EPSG:4326');
                // volt.feature = feature.getProperties();
                volt.feature = feat;
            }else if(layer.values_.id === 'inactif'){
                // volt.annonce = '';
            }else{
                // volt.annonce = feature.getProperties()._id;
                setTimeout(()=>{volt.annonce = feature.values_.features[0].getProperties()._id},200);
            }
        });
    });

    module.exports.mapOlMin[el].on('dblclick', function (evt) {
        module.exports.mapOlMin[el].forEachFeatureAtPixel(evt.pixel, function (feature) {
            console.log(feature);
        });
    });
    // const zoomslider = new ZoomSlider();
    // this.mapOlMin[el].addControl(zoomslider);
    const popup = new Overlay({
        element: document.getElementById('popup-map-'+el),
        positioning: 'bottom-left',
        offset: [-25, -25]
    });
    const selectStyle = new Style({
        fill: new Fill({
            color: '#eeeeee22',
        }),
        stroke: new Stroke({
            color: 'rgba(255, 255, 255, 0.9)',
            width: 2,
        }),
    });
    module.exports.mapOlMin[el].addOverlay(popup);
    return module.exports.mapOlMin[el];
}