<template>
  <div class="video-obs">
    <div class="closed-video" @click="closeVideo"><i class="fa-regular fa-circle-xmark"></i></div>
    <video id="tuto-video" class="video" controls>
      <source type="video/mp4" src="media/tuto_rapports.mp4" />
    </video>
  </div>
</template>
<script>
import volt from "@/volt";
export default {
  name: "VideoPlayer",
  emits: ['close-video'],
  methods: {
    closeVideo(){
      volt.tak('tuto-video').pause();
      this.$emit('close-video');
    }
  }
}
</script>

<style scoped>
.video-obs{
  position: fixed;
  top: 0px;
  z-index: 10000;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0);
}
.video{
  width: 90%;
  height: 90%;
}
.closed-video{
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  font-size: 30px;
  cursor: pointer;
  color: #aaaaaa;
}
</style>